import {React, useEffect} from "react";
import { Grid,Typography,Button,Table,TableCell,TableHead,TableRow,TableContainer,TableBody,Box,Modal } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useState } from "react";
import axios from "axios";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
const WalletApproval = () => {
    const [data ,setData] = useState([])
    const [nodata , setNodata] = useState(false);
    const [page, setPage] = useState(0);
    const [approvalOpen, setApprovalOpen] = useState(false);
    const approvalHandleOpen = (id) =>{
      setDeleteId(id)
      setApprovalOpen(true)
    };
    const approvalHandleClose = () => setApprovalOpen(false);
    const [rejectOpen, setRejectOpen] = useState(false);
    const rejectHandleOpen = (id) => {
      setDeleteId(id)
      setRejectOpen(true)
    };
    const rejectHandleClose = () => setRejectOpen(false);
    const [totalPages , setTotalPages] = useState();
    const user = JSON.parse(sessionStorage.getItem("userType"));
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [deleteId , setDeleteId] = useState("");
    const ErrorClose = () => setEmessage(false);
    const SuccessClose=() => setSmessage(false);
    const approvalStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: 5,
        outline:0,
        textAlign:'center',
        borderRadius:'10px'
      };

      let id = user.id;
      let type = user.type;

      // to load driver details in table

    const loadWalletApproval = () => {
      axios.get(`${process.env.REACT_APP_BASE_URL}getRideRequests/0/10`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          if(responce.data.status === 1){
            if(responce.data.details == ""){
              setNodata(true)
              
            }else{
              setNodata(false)
            }
              setData(responce.data.details)
              setTotalPages(responce.data.totalPages)
          }else {
            setData([])
          }
        }
       ).catch((error) => {console.log(error)})
    }

    // to handle pagination

    const handleChange = (event, value) => {
      setPage(value-1);
      axios.post(`${process.env.REACT_APP_BASE_URL}getRideRequests/${value-1}/10`,{type:type,id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {setData(responce.data.details)}
      ).catch((error) => {console.log(error)})
    };


    const approveReject = ( id , status ) =>{
      axios.post(`${process.env.REACT_APP_BASE_URL}rideRequestStatus`,{requestId:id,rideStatus:status},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
          if(responce.data.status === 1){
              setSmessage(true);
              loadWalletApproval();
              setSuccessMsg(responce.data.message);
              setApprovalOpen(false);
              setRejectOpen(false);
          }else{
            setEmessage(true);
            setErrorMsg(responce.data.message);
          }
      }
      ).catch((error) => {console.log(error)})
    }

    const table_row = data.map((row , index) => <TableRow key={row.id}
    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >{}
    <TableCell align="center" style={{maxWidth: 100}} component="td" >{(page)*10+index+1}</TableCell>
    <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.driverName}>{row.driverName}</TableCell>
    <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.driverPhone}>{row.driverPhone}</TableCell>
    <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.rides}>{row.rides}</TableCell>
    <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.price}>{row.price}</TableCell>
    <TableCell align="center" style={{maxWidth: 200}}  className="bordericons" component="td">
        {user.type === 1 ?<TableCell align="center"   component="td" >
            <CheckCircleOutlineIcon sx={{color:'#129547',cursor:'pointer'}} className="actioinIcon"
            onClick={()=>{approvalHandleOpen(row._id)}}/>
            <CancelOutlinedIcon sx={{color:'#E11F26',cursor:'pointer'}} className="actioinIcon"
            onClick={()=>{rejectHandleOpen(row._id)}} />
            </TableCell>:<TableCell align="center" style={{maxWidth: 155}} component="td" className="bordertd">
                        <Typography>Need to be approved</Typography>
                    </TableCell>}
            </TableCell>
    </TableRow>);


    useEffect(()=>{
      loadWalletApproval()
    },[])
    return(
        <>
          <Grid sx={{mx:3,my:2}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid> 
                    <Typography sx={{fontSize:'18px'}}>Wallet approval</Typography>
                </Grid>
            </Grid>
            <Grid>
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow className='headerBg'>
                        <TableCell className='tabel_cell tableHead' align="center">S.NO</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">DRIVER NAME</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">PHONE NUMBER</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">No of RIDES</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">AMOUNT</TableCell>
                        <TableCell className='tabel_cell tableHead' align="center">ACTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        nodata?<TableRow>
                        <TableCell colSpan={8} align="center">
                          <h2> No Wallet Data Found</h2>
                        </TableCell>
                        </TableRow>:table_row
                      }
                    </TableBody>                
                  </Table>
                </TableContainer>
                <Grid sx={{mt:1}} style={{ display: "flex", alignItems: "right", justifyContent:"right"}}>
                    <Stack spacing={2}>
                        <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                    </Stack>
                </Grid>
            </Grid>
          </Grid>
    <Modal
      open={approvalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
        <Box sx={approvalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you really want to Approve?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
            <Button className="yesButton" onClick={()=>approveReject(deleteId, 1)}>Yes</Button>
            <Button className="noButton"  onClick={approvalHandleClose}>No</Button>
          </Grid>
        </Box>
    </Modal>
      <Modal
        open={rejectOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={approvalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you really want to Reject?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
            <Button className="yesButton" onClick={()=>approveReject(deleteId, 2)}>Yes</Button>
            <Button className="noButton" onClick={rejectHandleClose}>No</Button>
          </Grid>
        </Box>
      </Modal>
        
      <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success" className="snackBar">
          {successMsg}
        </Alert>
    </Snackbar>
    <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
        <Alert onClose={ErrorClose} severity="error" className="snackError">
          {errorMsg}
        </Alert>
    </Snackbar>
        </>
    )

}
export default WalletApproval