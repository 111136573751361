import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NativeSelect from "@mui/material/NativeSelect";
import Button from '@mui/material/Button';
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { useEffect , useState } from "react";
import { useForm } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Backdrop from '@mui/material/Backdrop';
import Lottie from 'react-lottie'
import AlertJson from '../../../Assets/icons/alert.json'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
const AmbulanceDriver=()=>{

 //declarations of const for state management 

    const [district , setDistrict] = useState("");
    const [state , setState] = useState("")
    const [nodata , setNodata] = useState(false);
    const [page, setPage] = useState(0);
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [deleteId , setDeleteId] = useState("");
    const {register,handleSubmit,formState: { errors }} = useForm();
    const user = JSON.parse(sessionStorage.getItem("userType"));
    const [open, setOpen] = useState(false);
    const [districts , setDistricts] = useState([]);
    const [openView, setOpenView] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false); 
    const [openBlock, setOpenBlock] = useState(false);
    const [openunBlock, setOpenunBlock] = useState(false);
    const [states , setStates] = useState([]);
    const [ambulances , setAmbulances] = useState([]);
    const [ambType , setAmbType] = useState("");
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [data ,setData] = useState([])
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [totalPages , setTotalPages] = useState();

    const { register:register2 ,
      handleSubmit:handleSubmit2 ,
      formState:{errors:errors2}} = useForm();
    
    const [driverdetails , setDriverDetails] = useState({
      name:"",
      phoneNumber:"",
      email:"",
      state:"",
      district:"",
      ambulance:"",
      typeId:""
    })
    
    const [viewDriver , setViewDriver] = useState({
      name:"",
      phoneNumber:"",
      email:"",
      password:"",
      type:"",
      ambulance:"",
      typeId:""
    })

// default options for lottie

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: AlertJson,
      renderer: 'svg'
    }

// default styles for some modals

    const style = {
      position: 'absolute' ,
      top: '50%',
      left: '50%',
      width: 600,
      transform: 'translate(-50%, -50%)',
      bgcolor: ' #F1F3F6 !important;',
      border: '2px solid white',
      boxShadow: 24,
      p: '2% 4% 0% 4%',
      outline:0,
      borderRadius:'10px'
    };
    const DeleteStyle = {
      position: 'absolute' ,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: ' #F1F3F6 !important;',
      border: '2px solid white',
      boxShadow: 24,
      outline:'none',
      p: 5,
      textAlign:'center',
      borderRadius:'10px'
    };
    const approvalStyle = {
      position: 'absolute' ,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: ' #F1F3F6 !important;',
      border: '2px solid white',
      boxShadow: 24,
      p: 5,
      outline:0,
      textAlign:'center',
      borderRadius:'10px'
    };
// to load data automatically on load

    useEffect(() => { 
      loadDriverTable();
      allStates(); 
    },[]);

   // some functions to handle modals

    const handleClose = () => setOpen(false);
    const handleCloseView = () => setOpenView(false);
    const handleCloseEdit = () => setOpenEdit(false);
    const handleCloseBlock = () => setOpenBlock(false);
    const handleCloseunBlock = () => setOpenunBlock(false);
    const handleCloseDelete = () => setOpenDelete(false);
    const ErrorClose = () => setEmessage(false);
    const SuccessClose=() => setSmessage(false);


    const handleOpen = () => {
        setOpen(true);
    }
    const [approvalOpen, setApprovalOpen] = React.useState(false);
    const approvalHandleOpen = (id) =>
    {
      setDeleteId(id)
      setApprovalOpen(true);
    } 
    const approvalHandleClose = () => setApprovalOpen(false);
    const [rejectOpen, setRejectOpen] = React.useState(false);
    const rejectHandleOpen = (id) =>
    {
      setDeleteId(id)
      setRejectOpen(true);
    }
    
    const rejectHandleClose = () => setRejectOpen(false);
    
// to handle edit modal pen

    const handleOpenEdit = (id) => {
      setDeleteId(id)
      getDriverDetails(id)
      setOpenEdit(true)      
    }

// to handle view modal open
    
    const handleOpenView = (id) => {
      setDeleteId(id)
      getDriverDetails(id)
      setOpenView(true)
    }

// to get details of driver from id

    const getDriverDetails = (id) => {

      axios.get(`${process.env.REACT_APP_BASE_URL}getDriverById/${id}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
        let status = res.data.status;
        if(status === 1){
          setViewDriver({name:res.data.details.name , phoneNumber:res.data.details.phone , email:res.data.details.email , password:res.data.details.password , type:res.data.details.ambulanceType , ambulance:res.data.details.ambulanceNumber , typeId:res.data.details.ambulanceTypeId})
            
        }else if(status === 0){
          
        }
        
      }).catch((error) => {console.log(error)})
    
    }

// to open delete modal
    
    const handleOpenDelete = (id) => {
      setDeleteId(id)
      setOpenDelete(true);
    }

// functionality to handle delete

    const Delete = () =>{
      axios.get(`${process.env.REACT_APP_BASE_URL}deleteDriver/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          if(responce.data.status === 1){
            setSmessage(true)
            setSuccessMsg(responce.data.message)
            setOpenDelete(false)
            loadDriverTable()
          }else{
            setOpenDelete(false)
            setEmessage(true)
            
            setErrorMsg(responce.data.message)
          }
        }
      ).catch((error) => {console.log(error)})
    }

// to handle blockdriver modal

    const handleOpenBlock = (id , type) => {
      if(type === "block"){
          setOpenBlock(true)
          setDeleteId(id)
        }else if(type === "Unblock"){
          setOpenunBlock(true)
          setDeleteId(id)
        }
    };
    
// functional to block driver

    const Block = () =>{
      axios.get(`${process.env.REACT_APP_BASE_URL}blockDriver/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
            if(responce.data.status === 1){
              setSmessage(true);
              setSuccessMsg(responce.data.message)
              setOpenBlock(false)
              loadDriverTable()
            }else{
              setEmessage(true);
              setErrorMsg(responce.data.message)
            }
        }
      ).catch((error) => {console.log(error)})
    }

// functionality to handle unblock driver

    const unBlock = () =>{
      axios.get(`${process.env.REACT_APP_BASE_URL}blockDriver/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
            if(responce.data.status === 1){
              setSmessage(true);
              setSuccessMsg(responce.data.message)
              setOpenunBlock(false)
              loadDriverTable()
            }else{
              setEmessage(true);
              setErrorMsg(responce.data.message)
            }
        }
      ) .catch((error) => {console.log(error)})
    }

//functionality to approve/reject ambulance driver

    const approveReject = ( id , status ) =>{
      axios.get(`${process.env.REACT_APP_BASE_URL}approveRejectDriver/${id}/${status}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
          if(responce.data.status === 1){
              setSmessage(true);
              loadDriverTable();
              setSuccessMsg(responce.data.message);
              setApprovalOpen(false);
              setRejectOpen(false);
              
          }else{
              setEmessage(true);
              setErrorMsg(responce.data.message);
          }
      }
      ).catch((error) => {console.log(error)})
    }

// to get all states

    const allStates = () =>{
      axios.post(`${process.env.REACT_APP_BASE_URL}getStates`,{userId:""},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {setStates(responce.data.result)}
      ).catch((error) => {console.log(error)})
    }

// to handle state change

    const stateChange = (event) => {
      setDriverDetails({...driverdetails , state:event.target.value})
      axios.post(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:"" , stateId:event.target.value},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
              if(responce.data.status === 1){
              setDistricts(responce.data.result)
              }else{
                  setDistricts([])
              }
          }
      ).catch((error) => {console.log(error)})
    }

// to handle district change

    const districtChange = (event) => {
      setDriverDetails({...driverdetails,district:event.target.value});
      axios.post(`${process.env.REACT_APP_BASE_URL}ambulanceTypes`,{userId:""},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          console.log(responce)
            if(responce.data.status === 1){
              setAmbulances(responce.data.details)
            }else{
              setAmbulances([])
            }
        }
      ).catch((error) => {console.log(error)})
    };

// to handle ambulance type change

    const typeChange = (event) => {
      setDriverDetails({...driverdetails , typeId:event.target.value});
    }

// to handle edit ambulance type change

    const editTypeChange = (event) => {
      setViewDriver({...viewDriver , typeId:event.target.value});
    }

  
    let id = user.id;
    let type = user.type;

// to load driver details in table

    const loadDriverTable = () => {
      axios.post(`${process.env.REACT_APP_BASE_URL}driverList/0/10`,{type:type,id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          if(responce.data.status === 1){
            if(responce.data.details === ""){
              setNodata(true)
            }else{
              setNodata(false)
            }
              setData(responce.data.details)
              setTotalPages(responce.data.totalPages)
          }else {
            setData([])
          }
        }
       ).catch((error) => {console.log(error)})
    }
   
// to handle edit submit

    const onEditSubmit = (Form_data) => {
      axios.post(`${process.env.REACT_APP_BASE_URL}editDriver`,{name:viewDriver.name , phone:viewDriver.phoneNumber , email:viewDriver.email , password:viewDriver.password , id:deleteId , type:viewDriver.typeId , vehNumber:viewDriver.ambulance },{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          if(responce.data.status === 1){
            setSmessage(true)
            setSuccessMsg(responce.data.message)
            handleCloseEdit()
            loadDriverTable();
          }else if(responce.data.status === 0){
            setEmessage(true)
            setErrorMsg(responce.data.message)
          }
        }
      ).catch((error) => {console.log(error)})
    }

// to handle pagination

    const handleChange = (event, value) => {
      setPage(value-1);
      axios.post(`${process.env.REACT_APP_BASE_URL}driverList/${value-1}/10`,{type:type,id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {setData(responce.data.details)}
      ).catch((error) => {console.log(error)})
    };

// functionality to add driver

    const onSubmit = (Form_data) => {
      setLoaderOpen(true)
      axios.post(`${process.env.REACT_APP_BASE_URL}addDriver`,{name:driverdetails.name , email:driverdetails.email , phone:driverdetails.phoneNumber , state: driverdetails.state , district:driverdetails.district , addedBy:type , addedById:id , vehNumber: driverdetails.ambulance, type:driverdetails.typeId},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
        let status = res.data.status;
        if(status === 1){
          setLoaderOpen(false)
          setSmessage(true);
          setSuccessMsg(res.data.message)
          setOpen(false);
          loadDriverTable();
          setDriverDetails({
            ...driverdetails,
            name:"",
            phoneNumber:"",
            email:"",
            state:"",
            district:"",
            ambulance:"",
            typeId:""
          })
        }else if(status === 0){
          setLoaderOpen(false)
            setEmessage(true)
            setErrorMsg(res.data.message)
        }
      }).catch((error) => {console.log(error)})
    }

// functionality for search filter

    const searchFilter = (e) =>{
      if(e === ""){
        setNodata(false)
        loadDriverTable();
      }else{
        axios.post(`${process.env.REACT_APP_BASE_URL}driverSearch/0/10`,{search:e , type:1 , id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 0){
              setNodata(true)
            }else if(responce.data.status === 1){
              setNodata(false);
              setData(responce.data.details)
              setTotalPages(responce.data.totalPages)
            }
          }
        ).catch((error) => {console.log(error)})
      }   
    }

// html loop for driver details table

    const table_row = data.map((row , index) => <TableRow key={row.id}
        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >{}
        <TableCell align="center" style={{maxWidth: 100}} component="td" >{(page)*10+index+1}</TableCell>
        <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.name}>{row.name}</TableCell>
        <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.phone}>{row.phone}</TableCell>
        <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.email}>{row.email}</TableCell>
        <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.ambulanceType}>{row.ambulanceType}</TableCell>
        <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.rides}>{row.rides}</TableCell>
        {row.approvalStatus === 1 ?<TableCell align="center" style={{maxWidth: 155}}  component="td" >
            <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}}
              onClick={() => {handleOpenView(row._id)}}
             />
            <BorderColorOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}}
            onClick={() => {handleOpenEdit(row._id)}}
             />
            <DeleteOutlineOutlinedIcon className="actioinIcon" sx={{color:'#E11F26',cursor:'pointer'}}
            onClick={() => {handleOpenDelete(row._id)}}
             />
            {!row.blockStatus?<BlockOutlinedIcon className="actioinIcon" sx={{color:'#A70006',cursor:'pointer'}}
             onClick={()=>{handleOpenBlock(row._id , "Unblock")} }
             />:<BlockOutlinedIcon className="actioinIcon" sx={{cursor:'pointer'}}
             onClick={()=>{handleOpenBlock(row._id , "block")} }
             />}
        </TableCell>:
        <TableCell align="center" style={{maxWidth: 200}} className="bordericons" component="td">
        {user.type === 1 ?<TableCell align="center" component="td" >
            <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}}
              onClick={() => {handleOpenView(row._id)}}
             />    
            <CheckCircleOutlineIcon sx={{color:'#129547',cursor:'pointer'}} className="actioinIcon"
            //  onClick={()=>approveReject(row._id , 1)}
            onClick={()=>{approvalHandleOpen(row._id)}}
             />
            <CancelOutlinedIcon sx={{color:'#E11F26',cursor:'pointer'}} className="actioinIcon"
            //  onClick={()=>approveReject(row._id , 2)}
            onClick={()=>{rejectHandleOpen(row._id)}}
             />
            </TableCell>:<TableCell align="center" style={{maxWidth: 155}} component="td" className="bordertd">
                        <Typography>Need to be approved</Typography>
                    </TableCell>}
            </TableCell>}
        
        </TableRow>);

// to set options for district , state and ambulance type    

  const DistrictOptions = districts.map(option =>
  <MenuItem key={option._id} value={option._id}>{option.district}</MenuItem>)
  const stateOptions = states.map(option =><MenuItem key={option._id} value={option._id}>{option.state}</MenuItem>)
  const ambOptions = ambulances.map(option =><MenuItem key={option._id} value={option._id}>{option.type}</MenuItem>)

  return(
        <>
        <Grid sx={{mx:3,my:2}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid> 
                    <Typography sx={{fontSize:'18px'}}>Ambulance driver</Typography>
                </Grid>
                <Grid sx={{ display: 'flex', mb:1 }}>
                    <Grid>
                        <TextField className="text_field" placeholder="search" onChange={e => {searchFilter(e.target.value)}} fullWidth InputProps={{
                            startAdornment: (
                           <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                           ),
                        }}/>
                    </Grid>
                    <Grid sx={{ display: 'flex',alignItems:'center',mx:3 }}>
                        <Typography className="add_text">Add</Typography>
                        <AddCircleIcon className="Add_icon"  onClick={handleOpen} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead className="tableHeader">
                            <TableRow>
                              <TableCell className="tableHead">S.NO</TableCell>
                              <TableCell className="tableHead">NAME</TableCell>
                              <TableCell className="tableHead">PHONE NUMBER</TableCell>
                              <TableCell className="tableHead">EMAIL</TableCell>
                              <TableCell className="tableHead">TYPE</TableCell>
                              <TableCell className="tableHead">No of RIDES</TableCell>
                              <TableCell className="tableHead">ACTIONS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                          nodata?<TableRow>
                          <TableCell colSpan={8} align="center">
                             <h2> No Driver Data Found</h2>
                          </TableCell>
                        
                          </TableRow>:table_row
                          }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid sx={{mt:1}} style={{ display: "flex", alignItems: "right", justifyContent:"right"}}>
                    <Stack spacing={2}>
                        <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                    </Stack>
                </Grid>
                
                
            </Grid>
        </Grid> 
         {/*Start MOdal */}
         <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
           <Box sx={style}>
                <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
                <Grid>
                  <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                    Add ambulance driver 
                  </Typography>
                </Grid>
                <Box sx={{ width: '100%' }}>
                    <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item md={6}>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>Driver name</FormLabel>
                                <TextField name="name" value={driverdetails.name} className={'text_field '}  {...register('name',{required: true})}
                                onChange={(e)=>setDriverDetails({...driverdetails , name:e.target.value})}
                                />
                                {errors.name && errors.name.type === "required" && <p className='error mt-0'>Please enter name</p>}
                            </Grid>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>Email</FormLabel>
                                <TextField name="email" value={driverdetails.email} className={'text_field '}  {...register('email',{required: true, pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                                onChange={(e)=>setDriverDetails({...driverdetails , email:e.target.value})}
                                />{errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter Email </p>}
                                {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>Please enter valid Email</p>}
                            </Grid>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>Phone number</FormLabel>
                                <TextField name="phone" value={driverdetails.phoneNumber} className={'text_field '}  {...register('phone',{required: true,pattern:/^\d{10}$/})}
                                onChange={(e)=>setDriverDetails({...driverdetails , phoneNumber:e.target.value})}
                                ></TextField>
                                {errors.phone && errors.phone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                                {errors.phone && errors.phone.type === "pattern"  && <p className='error mt-0'>Please enter valid Number</p>}
                            </Grid>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>State</FormLabel>
                                <Select
                                disableUnderline
                                displayEmpty
                                className='selectOptions'
                                fullWidth
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={driverdetails.state}
                                placeholder="Select State"
                                {...register('state',{required: true})}
                                onChange={stateChange}
                                >
                                <MenuItem value="">
                                <em>Select State</em>
                                </MenuItem>
                                {stateOptions}
                              </Select>
                              {errors.state && errors.state.type === "required" && <p className='error mt-0'>Please enter state</p>}
                            </Grid>
                          </Grid>
                          <Grid item md={6}>
                              <Grid item sx={{mt:2}}>
                                  <FormLabel >District</FormLabel>
                                  <Select disableUnderline
                                  className='selectOptions'fullWidth
                                  displayEmpty
                                  placeholder="Select District"
                                  defaultValue={30}
                                  value={driverdetails.district}
                                  {...register('district',{required: true})}
                                  onChange={districtChange}>
                                  <MenuItem value="">Select District</MenuItem>
                                  {DistrictOptions}
                                  </Select>
                                  {errors.district && errors.district.type === "required" && <p className='error mt-0'>Please enter district</p>}
                              </Grid>
                              <Grid item sx={{mt:2}}>
                                <FormLabel>Type</FormLabel>
                                <Select disableUnderline
                                className='selectOptions' 
                                fullWidth
                                displayEmpty
                                defaultValue={30}
                                placeholder="Select Type"
                                value={driverdetails.typeId}
                                {...register('ambType',{required: true})}
                                onChange={typeChange}>
                                <MenuItem value="">Select Type</MenuItem>
                                {ambOptions}
                                </Select>
                                {errors.ambType && errors.ambType.type === "required" && <p className='error mt-0'>Please enter ambulance type</p>}
                              </Grid>
                              <Grid item sx={{mt:2}}>
                                <FormLabel>Ambulance number</FormLabel>
                                <TextField name="ambulanceNumber" value={driverdetails.ambulance} className={'text_field '}{...register('ambulanceNumber',{required: true})}
                                onChange={(e)=>setDriverDetails({...driverdetails , ambulance:e.target.value})}
                                />
                                  {errors.ambulanceNumber && errors.ambulanceNumber.type === "required" && <p className='error mt-0'>Please enter ambulance number </p>}
                              </Grid>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                          <Grid item sx={{my:3}}>
                              <Button className="customerLogin" bgColor='#129547' onClick={handleSubmit(onSubmit)}>Add</Button>
                              <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loaderOpen}
                                onClick={handleClose}
                                invisible={true}
                              >
                              <Lottie 
                                options={defaultOptions}
                                height={100}
                                width={100}
                              />
                              </Backdrop>
                          </Grid> 
                        </Grid>
                    </Box>
                  </Box>
      </Modal>
         {/* End Modal */}

      {/*Start View Modal */}
        <Modal
        open={openView}
        // onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
          <Box sx={style}>
              <CloseIcon onClick={()=>{handleCloseView()}} className={"closeIconPosition"}/>
              <Grid className={"titleModal"}>
                  <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                  Ambulance driver details
                  </Typography>
              </Grid>
              <Box sx={{ width: '100%' }}>
                  <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item md={6}>
                            <Grid item  sx={{mt:2}}>
                                <FormLabel>Driver name</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'} >{viewDriver.name}</Typography>
                            </Grid>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>Email</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'} >{viewDriver.email}</Typography>
                            </Grid>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>Phone number</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'} >{viewDriver.phoneNumber}</Typography>
                            </Grid>
                        </Grid>
                          <Grid item md={6}>
                              <Grid item sx={{mt:2}}>
                                <FormLabel>Type</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'}>{viewDriver.type}</Typography>
                              </Grid>
                              <Grid item sx={{mt:2}}>
                                  <FormLabel>Ambulance number</FormLabel>
                                  <Typography fullWidth name="name" className={'viewBox'}>{viewDriver.ambulance}</Typography>
                              </Grid>
                              <Grid item sx={{mt:2}}>
                                  <FormLabel>Password</FormLabel>
                                  <Typography fullWidth name="name" className={'viewBox'}>{viewDriver.password}</Typography>
                              </Grid>
                          </Grid>
                      </Grid>
                      <Grid container justifyContent='center'>
                          <Grid item sx={{my:3}}>
                            <Button className="customerLogin" bgColor='#129547' onClick={()=>{handleCloseView()}} >Ok</Button>
                          </Grid> 
                      </Grid>
               </Box>
          </Box>
      </Modal>


{/* End View Modal */}

{/* Start Edit Modal */}
    <Modal
        open={openEdit}
        // onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
          <Box sx={style}>
                <CloseIcon onClick={()=>{handleCloseEdit()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                    Edit ambulance driver
                    </Typography>
                </Grid>
                <Box sx={{ width: '100%' }}>
                    <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item md={6}>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>Driver name</FormLabel>
                                <TextField name="editName" value={viewDriver.name} className={'text_field'}
                                {...register2('editName',{required: true})}
                                onChange={
                                  (event)=>{setViewDriver({...viewDriver , name:event.target.value})}
                                }
                                ></TextField>
                                { viewDriver.name === "" && errors2.editName && errors2.editName.type === "required" && <p className='error mt-0'>Please enter name</p>}
                            </Grid>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>Email</FormLabel>
                                <TextField fullWidth name="editEmail" value={viewDriver.email} className={'text_field'}
                                {...register2('editEmail',{required: true, pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                                onChange={
                                  (event)=>{setViewDriver({...viewDriver , email:event.target.value})}
                                }
                                ></TextField>
                                { viewDriver.email === "" && errors2.editEmail && errors2.editEmail.type === "required" && <p className='error mt-0'>Please enter Email </p>}
                                {errors2.editEmail && errors2.editEmail.type === "pattern"  && <p className='error mt-0'>Please enter valid Email</p>}
                            </Grid>
                            <Grid item sx={{mt:2}}  >
                                  <FormLabel>Phone number</FormLabel>
                                  <TextField value={viewDriver.phoneNumber} className={'text_field'}
                                  {...register2('EditPhone',{required: true,pattern:/^\d{10}$/})}
                                  onChange={
                                    (event)=>{setViewDriver({...viewDriver , phoneNumber:event.target.value})}
                                  }></TextField>
                                  { viewDriver.phoneNumber === "" && errors2.EditPhone && errors2.EditPhone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                                  {errors2.EditPhone && errors2.EditPhone.type === "pattern"  && <p className='error mt-0'>Please enter valid Number</p>}
                            </Grid>
                        </Grid>
                        <Grid item md={6}>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>Type</FormLabel>
                                <NativeSelect 
                                disableUnderline
                                className='selectOptions'
                                fullWidth
                                value={viewDriver.typeId}
                                {...register2('editambType',{required: true})}
                                onChange={editTypeChange}
                                placeolder="Select City/Mandal">
                                <option value="62cd71bdc73cfcaa12905de0">
                                  ICU
                                </option>
                                <option value="62cd720ec73cfcaa12905de3">
                                  GENERAL
                                </option>
                                <option value="62cd72c1c73cfcaa12905de6">
                                  SEMI ICU
                                </option>
                                </NativeSelect>
                            </Grid>
                            <Grid item sx={{mt:2}}  >
                                  <FormLabel>Ambulance number</FormLabel>
                                  <TextField value={viewDriver.ambulance} className={'text_field'}
                                  {...register2('editambulanceNumber',{required: true})}
                                  ></TextField>
                                  { viewDriver.ambulance === "" && errors2.editambulanceNumber && errors2.editambulanceNumber.type === "required" && <p className='error mt-0'>Please enter ambulance number </p>}
                            </Grid>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>Password</FormLabel>
                                <TextField value={viewDriver.password} className={'text_field'}
                                {...register2('editPassword',{required: true,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/})}
                                onChange={
                                  (event)=>{setViewDriver({...viewDriver , password:event.target.value})}
                                }
                                ></TextField>
                                {viewDriver.password === "" && errors2.editPassword && errors2.editPassword.type === "required" && <p className='error mt-0'>Please enter password</p>}
                                {errors2.editPassword && errors2.editPassword.type === "pattern" && <p className='error mt-0'>please enter valid Password</p>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid item sx={{my:3}} >
                            <Button className="customerLogin" bgColor='#129547' onClick={handleSubmit2(onEditSubmit)}>Update</Button>
                          </Grid> 
                    </Grid>
                </Box>
          </Box>
      </Modal>
{/* End Edit Modal */}


{/*START DELETE MODAL */}
           
      <Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete the driver?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
            <Button className="yesButton" onClick={Delete}>Yes</Button>
            <Button className="noButton" onClick={handleCloseDelete}>No</Button>
          </Grid>
        </Box>
      </Modal>

    {/*END DELETE MODAL  */}

  {/*START BLOCK MODAL  */}
      <Modal
        open={openBlock}
        // onClose={handleCloseBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to block the driver?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
            <Button className="yesButton" onClick={Block} >Yes</Button>
            <Button className="noButton" onClick={handleCloseBlock}>No</Button>
          </Grid>
        </Box>
      </Modal>

    {/*END BLOCK MODAL  */}
    {/*START UNBLOCK MODAL  */}
    <Modal
      open={openunBlock}
      // onClose={handleCloseBlock}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={DeleteStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Do you want to un-block the driver?
        </Typography>
        <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
          <Button className="yesButton" onClick={unBlock} >Yes</Button>
          <Button className="noButton" onClick={handleCloseunBlock}>No</Button>
        </Grid>
      </Box>
    </Modal>

    {/*END UNBLOCK MODAL  */}

    <Modal
      open={approvalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
        <Box sx={approvalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you really want to Approve?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
            <Button className="yesButton" onClick={()=>approveReject(deleteId, 1)}
              >Yes</Button>
            <Button className="noButton" onClick={approvalHandleClose}>No</Button>
          </Grid>
        </Box>
    </Modal>
      <Modal
        open={rejectOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={approvalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you really want to Reject?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
            <Button className="yesButton" onClick={()=>approveReject(deleteId, 2)} >Yes</Button>
            <Button className="noButton" onClick={rejectHandleClose}>No</Button>
          </Grid>
        </Box>
      </Modal>

    <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success" className="snackBar">
          {successMsg}
        </Alert>
    </Snackbar>
    <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
        <Alert onClose={ErrorClose} severity="error" className="snackError">
          {errorMsg}
        </Alert>
    </Snackbar>
  </>

    )
}

export default AmbulanceDriver