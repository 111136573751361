import React from "react";
import './hospitals.css';
import { Grid, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import NativeSelect from "@mui/material/NativeSelect";
import Button from '@mui/material/Button';
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { useEffect , useState } from "react";
import { useForm } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Geocode from "react-geocode";
import LocationIcon from '../../../Assets/icons/location.svg'
import {useJsApiLoader , GoogleMap , MarkerF } from '@react-google-maps/api';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Backdrop from "@mui/material/Backdrop";
import AlertJson from '../../../Assets/icons/alert.json'
import Lottie from 'react-lottie'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
const AdminHospitals=()=>{

    const [area , setArea] = useState("");
    const [state , setState] = useState("")
    const [states , setStates] = useState([])
    const [othercity , setOthercity] = useState(true);
    const [othercityText , setOthercityText] = useState("");
    const [city , setCity] = useState("");
    const [district , setDistrict] = useState("");
    const [other , setOther] = useState(true);
    const [inCityerror , setInCityerror] = useState(false);
    const [nodata , setNodata] = useState(false);
    const [inAreaerror , setInAreaError] = useState(false);
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [page, setPage] = useState(0);
    const {register,handleSubmit,formState: { errors }} = useForm();
    const {register:register2,handleSubmit:handleSubmit2,formState: { errors:errors2 }} = useForm();
    const user = JSON.parse(sessionStorage.getItem("userType"));
    const [center , setCenter] = useState({lat:"", lng:""})
    const [address , setAddress] = useState("")
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [districts , setDistricts] = useState([]);
    const [cities , setCities] = useState([]);
    const [areas , setAreas] = useState([]);
    const [otherAreaText , setOtherAreaText] = useState();
    const [deleteId , setDeleteId] = useState("");
    const [open, setOpen] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openBlock, setOpenBlock] = useState(false);
    const [openunBlock, setOpenunBlock] = useState(false);
    const [openMap, setOpenMap] = useState(false);
    const [openEditMap , setOpenEditMap] = useState(false);
    const [data ,setData] = useState([]);
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [cityName , setCityName] = useState("")
    const [totalPages , setTotalPages] = useState();
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [editothercity, setEditothercity] = useState(true);
    const [editotherarea , setEditotherarea] = useState(true)
    const [editother , setEditother] = useState(true);
    const [handleStateChange , setHandleStateChange] = useState(true);
    const [handleDistrictChange , setHandleDistrictChange] = useState(true);
    const [handleCityChange , setHandleCityChange] = useState(true);


    useEffect(() => { 
        loadHospitalTable();
        allStates();
    },[]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseView = () => setOpenView(false);
    const handleCloseEdit = () => setOpenEdit(false);
    const handleCloseDelete = () => setOpenDelete(false);
    const handleCloseBlock = () => setOpenBlock(false);
    const handleCloseunBlock = () => setOpenunBlock(false);
    const handleCloseMap = () => setOpenMap(false);
    const [approvalOpen, setApprovalOpen] = React.useState(false);
    const approvalHandleOpen = (id) => 
    {
        setDeleteId(id)
      setApprovalOpen(true);
    }
    
    const approvalHandleClose = (id) => setApprovalOpen(false);
    const [rejectOpen, setRejectOpen] = React.useState(false);
    const rejectHandleOpen = (id) => {
        setDeleteId(id)
        setRejectOpen(true);
    }
    const rejectHandleClose = () => setRejectOpen(false);

    const defaultOptions = {
       loop: true,
       autoplay: true,
       animationData: AlertJson,
       renderer: 'svg'
    }

    const [viewHospital , setViewHospital] = useState({
        name:"",
        phoneNumber:"",
        email:"",
        password:"",
        area:"",
        areaId:"",
        otherArea:"",
        state:"",
        stateId:"",
        districtId:"",
        district:"",
        city:"",
        cityId:"",
        otherCity:"",
        address:""
    })
    
    const style = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height:500,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: '2% 2% 0% 2%',
        outline:0,
        borderRadius:'10px'
    };
    const approvalStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: 5,
        outline:0,
        textAlign:'center',
        borderRadius:'10px'
      };
    const MapStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height:500,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: '2% 2% 0% 2%',
        outline:0,
        borderRadius:'10px'
    };
    const DeleteStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        outline:'none',
        p: 5,
        textAlign:'center',
        borderRadius:'10px'
    };


    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey : process.env.REACT_APP_GOOGLE_KEY,
    })

    const editCityChange = (e) => {
        setViewHospital({
            ...viewHospital,
            cityId:e.target.value,
            areaId:""
        })
        if(e.target.value === "10"){
            setEditothercity(false);
        }else{
            setEditothercity(true);
        }
    }

    const editAreaChange = (e) => {
        setViewHospital({
            ...viewHospital,
            areaId:e.target.value
        })
        if(e.target.value === "10"){
            setEditotherarea(false);
        }else{
            setEditotherarea(true);
        }
    }


    const handleOpenEdit = (id) => {
        getHsptlDetails(id)
        setOpenEdit(true)
        setDeleteId(id)
    
    };

    //console.log(errors2,"errors2")
    
    const districtChange = (event) => {
        setDistrict(event.target.value);
        getCities(event.target.value);
    };

    const getCities = (event) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}getCities/`,{userId:"" , districtId:event},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setCities(responce.data.result)
                }else {
                    setCities([])
                }
            }
        ).catch((error) => {console.log(error)})
    }
    

    const areaChange = (event) => {
        setArea(event.target.value);
        if(event.target.value === '10'){
            setOther(false);
        }else{
            setOther(true);
        }
    };

    const onEditSubmit = (data) => {
        var checkeditcity = "";
        var checkeditarea = "";
        var checkCity = "";
        var chechArea = "";
        if(editothercity === false){
            checkeditcity = "other";
            checkCity = viewHospital.city;
        }else if(editothercity === true){
            checkeditcity = "";
            checkCity = viewHospital.cityId;
        }

        if(editotherarea === false){
            checkeditarea = "other";
            chechArea = viewHospital.area;
        }else if(editotherarea === true){
            checkeditarea = "";
            chechArea = viewHospital.areaId;
        }

        let addressInput = `${viewHospital.address} ${viewHospital.area} , ${viewHospital.city} , ${viewHospital.district} , ${viewHospital.state}`;
        
        if(center.lat === ""){
            
            
            console.log(addressInput);
                Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
                Geocode.setLanguage("en");
                Geocode.fromAddress(addressInput).then(
                    (response) => {
                        
                      const { lat, lng } = response.results[0].geometry.location;
                      axios.post(`${process.env.REACT_APP_BASE_URL}editHospital`,{id:deleteId , name:viewHospital.name , email:viewHospital.email , phone:viewHospital.phoneNumber , state:viewHospital.stateId , district:viewHospital.districtId , cityType:checkeditcity , city:checkCity , areaType:checkeditarea , area:chechArea , address:viewHospital.address , latitude:lat , longitude:lng },{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
                        let status = res.data.status;
                        if(status === 1){
                            setLoaderOpen(false)
                            window.location.reload()
                            handleClickOpen()
                            setSuccessMsg(res.data.message)
                            setSmessage(true);
                            setEmessage(false);
                            
                        }else if(status === 0){
                            setLoaderOpen(false)
                            setErrorMsg(res.data.message)
                            setEmessage(true);
                            setSmessage(false);
                        }
        
                    }).catch((error) => {console.log(error)})
                    },
                    (error) => {
                      console.error(error);
                    }
                  );
                
        }else{
            axios.post(`${process.env.REACT_APP_BASE_URL}editHospital`,{id:deleteId , name:viewHospital.name , email:viewHospital.email , phone:viewHospital.phoneNumber , state:viewHospital.stateId , district:viewHospital.districtId , cityType:checkeditcity , city:checkCity , areaType:checkeditarea , area:chechArea , address:viewHospital.address , latitude:center.lat , longitude:center.lng },{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
                let status = res.data.status;
                if(status === 1){
                    setLoaderOpen(false)
                    window.location.reload()
                    handleClickOpen()
                    setSuccessMsg(res.data.message)
                    setSmessage(true);
                    setEmessage(false);
                    
                }else if(status === 0){
                    setLoaderOpen(false)
                    setErrorMsg(res.data.message)
                    setEmessage(true);
                    setSmessage(false);
                }

            }).catch((error) => {console.log(error)})
        }
      }


    const stateChange = (event) => {
        setState(event.target.value)
        getDistricts(event.target.value)
        
    }

    const getDistricts = (event)=> {
        axios.post(`${process.env.REACT_APP_BASE_URL}getDistrict/`,{userId:"" , stateId:event},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                setDistricts(responce.data.result)
                // console.log("hello")
                }else{
                    setDistricts([])
                    // console.log("hello1")
                }
            }
        ).catch((error) => {console.log(error)})
    }


    const cityChange = (event) => {
        setCity(event.target.value);
            if(event.target.value == "10"){
                setOthercity(false);
            }else{
                setOthercity(true);
            }
            
        setOther(true);
        getAreas(event.target.value);
    };

    const getAreas = (event) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}getAreas/${event}`,{userId:"" , districtId:event},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                    if(responce.data.status === 1){
                        setAreas(responce.data.result)
                    }else{
                        setAreas([])
                    }
                    
                
            }
        ).catch((error) => {console.log(error)})
    }

  
    const handleOpenView = (id) => {
        setDeleteId(id)
        getHsptlDetails(id)
        setOpenView(true)
    };

    const getHsptlDetails = (id) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}getHospitalById/${id}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            let status = res.data.status;
            if(status === 1){
                setViewHospital({...viewHospital , name:res.data.details.name , phoneNumber:res.data.details.phone , email:res.data.details.email , password:res.data.details.password , area:res.data.details.areaName , areaId:res.data.details.area , district:res.data.details.districtName , districtId:res.data.details.district , state:res.data.details.stateName , stateId:res.data.details.state , city:res.data.details.cityName , cityId:res.data.details.city , address:res.data.details.address})
            
            }else if(status === 0){
          
            }
            
        }).catch((error) => {console.log(error)})
    }

  
    const handleOpenDelete = (id) => {
        setDeleteId(id)
        setOpenDelete(true);
    }


    const Delete = () =>{
        axios.get(`${process.env.REACT_APP_BASE_URL}deleteHospital/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setSmessage(true)
                    setSuccessMsg(responce.data.message)
                    setOpenDelete(false)
                    loadHospitalTable()
                }else{
                    setEmessage(true)
                    setErrorMsg(responce.data.message)
                }
            }
        ).catch((error) => {console.log(error)})
    }


    const handleOpenBlock = (id , type) => {
        if(type === "block"){
            setOpenBlock(true)
            setDeleteId(id)
        }else if(type === "Unblock"){
            setOpenunBlock(true)
            setDeleteId(id)
        }
    };


    const Block = () =>{
        axios.get(`${process.env.REACT_APP_BASE_URL}blockHospital/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setSmessage(true);
                    setSuccessMsg(responce.data.message)
                    setOpenBlock(false)
                    loadHospitalTable()
                }else{
                    setEmessage(true);
                    setErrorMsg(responce.data.message)
                }
            }
        ).catch((error) => {console.log(error)})
    }


    const unBlock = () =>{
        axios.get(`${process.env.REACT_APP_BASE_URL}blockHospital/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setSmessage(true);
                    setSuccessMsg(responce.data.message)
                    setOpenunBlock(false)
                    loadHospitalTable()
                }else{
                    setEmessage(true);
                    setErrorMsg(responce.data.message)
                }
            }
        ).catch((error) => {console.log(error)})
    }


    const handleOpenMap = () => {
        setOpenMap(true);
        handleClose()
    }

    const handleOpenEditMap = () => {
        setOpenEditMap(true);
        setOpenEdit(false);
    }


    const allStates = () =>{
        axios.post(`${process.env.REACT_APP_BASE_URL}getStates`,{userId:""},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {setStates(responce.data.result)}
        ).catch((error) => {console.log(error)})
    }



    let id = user.id;

    const loadHospitalTable = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}hospitalList/0/10`,{type:user.type,id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                if(responce.data.status === 1){
                    setData(responce.data.details)
                    setTotalPages(responce.data.totalPages)
                }else{
                    // setNodata(true)
                }  
            }
        ).catch((error) => {console.log(error)})
    }
  

    const setMap = () => {
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            setCenter({
                lat:position.coords.latitude,
                lng:position.coords.longitude
            })
            Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
            Geocode.setLanguage("en");
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
              const address = response.results[0].formatted_address;
              setAddress(address);
            },
            (error) => {
              console.error(error);
            }
          );
          });
    }

    //functionality to approve/reject hospital

    const approveReject = ( id , status ) =>{
        axios.get(`${process.env.REACT_APP_BASE_URL}approveRejectHospital/${id}/${status}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
            if(responce.data.status === 1){
                setSmessage(true);
                loadHospitalTable()
                setSuccessMsg(responce.data.message)
                setApprovalOpen(false);
                setRejectOpen(false);
                
            }else{
                setEmessage(true);
                setErrorMsg(responce.data.message)
            }
        }
        ).catch((error) => {console.log(error)})
      }
  

    const setMarker = (ev) => {
        setCenter({
            lat:ev.latLng.lat(),
            lng:ev.latLng.lng()
        })
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.setLanguage("en");
        Geocode.fromLatLng(center.lat, center.lng).then(
            (response) => {
              const address = response.results[0].formatted_address;
              let city = address.split(',')
              setCityName(city[2]);
              setAddress(address);
              setViewHospital({
                ...viewHospital,
                address:address
              })
            },
            (error) => {
              console.error(error);
            }
          );
    }

      
    const handleChange = (event, value) => {
        setPage(value-1);
        axios.post(`${process.env.REACT_APP_BASE_URL}hospitalList/${value-1}/10`,{type:1,id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {setData(responce.data.details)}
        ).catch((error) => {console.log(error)})
    };


    const handleClickOpen = () => {
        setOpen(false);
    };


    const ErrorClose = () => {
        setEmessage(false)
    }


    const SuccessClose=() => {
        setSmessage(false);
    }


    const table_row = data.map((row , index) => <TableRow key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >{}
        <TableCell align="center" style={{maxWidth: 100}} component="td">{(page)*10+index+1}</TableCell>
        <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.name}>{row.name}</TableCell>
        <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.email}>{row.email}</TableCell>
        <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.districtName}>{row.districtName}</TableCell>
        <TableCell align="center" style={{maxWidth: 130}} component="td" title={row.address  }>{row.address}</TableCell>
        
        {row.approvalStatus === 1 ?
        <TableCell align="center" style={{maxWidth: 300}} component="td">
        <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
        onClick={() => {handleOpenView(row._id)} }
        />
        <BorderColorOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
        onClick={() => {handleOpenEdit(row._id)}}
        />
        <DeleteOutlineOutlinedIcon className="actioinIcon" sx={{color:'#E11F26',cursor:'pointer'}} 
        onClick={() => {handleOpenDelete(row._id)}}
        />
        {!row.blockStatus?<BlockOutlinedIcon className="actioinIcon" sx={{color:'#A70006',cursor:'pointer'}}
         onClick={()=>{handleOpenBlock(row._id , "Unblock")} }
         />:<BlockOutlinedIcon className="actioinIcon" sx={{cursor:'pointer'}}
         onClick={()=>{handleOpenBlock(row._id , "block")} }
         />}
        </TableCell> : 
                <TableCell align="center" style={{maxWidth: 200}} className="bordericons" component="td">
                    {user.type === 1 ?<TableCell align="center"  component="td">
                    <VisibilityOutlinedIcon className="actioinIcon" sx={{color:'#129547',cursor:'pointer'}} 
                        onClick={() => {handleOpenView(row._id)} }
                        />
                        <CheckCircleOutlineIcon sx={{color:'#129547',cursor:'pointer'}} className="actioinIcon" 
                         onClick={()=>{approvalHandleOpen(row._id)}}/>
                        <CancelOutlinedIcon sx={{color:'#E11F26',cursor:'pointer'}} className="actioinIcon"
                          onClick={()=>{rejectHandleOpen(row._id)}}
                        //  onClick={()=>approveReject(row._id , 2)}
                         />
                    </TableCell>:<TableCell align="center"  component="td" className="bordertd" >
                        <Typography>Need to be approved</Typography>
                    </TableCell>}
                    
                
        </TableCell>}
        
      
        </TableRow>);


        
        
    const onSubmit = async (Form_data)=>{
        setLoaderOpen(true)
        var incity = "";
        var incityType = "";
        var inarea = "";
        var inareaType = "";

        if(othercity === false){
            if(othercityText !== "" ){
                setInCityerror(false);
                incity = othercityText;
                incityType = "other" ;
                
            }else{
                setInCityerror(true);
            }
        }else if(othercity === true){
            incity = Form_data.city ;
            incityType = "" ;
            setInCityerror(false);
        }

        if(other === false){
            if(otherAreaText !== ""){
                inarea = otherAreaText ;
                inareaType = "other" ;
            }else{
                setInAreaError(true);
            } 
        }else if(other === true){
            inarea = Form_data.area ;
            inareaType = "" ;
            setInAreaError(false);
        }

        let addressInput = `${Form_data.address} ${Form_data.inarea} , ${Form_data.incity} , ${Form_data.district} , ${Form_data.state}`;
        console.log(addressInput);
            
            axios.post(`${process.env.REACT_APP_BASE_URL}addHospital`,{name:Form_data.name ,email:Form_data.email , phone:Form_data.phone , district:district , cityType:incityType , city:incity , areaType:inareaType, area:inarea, address:address , latitude:center.lat , longitude:center.lng , addedBy:user.type , addedById:user.id , state:state},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
                let status = res.data.status;
                if(status === 1){
                    setLoaderOpen(false)
                    window.location.reload()
                    handleClickOpen()
                    setSuccessMsg(res.data.message)
                    setSmessage(true);
                    setEmessage(false);
                    
                }else if(status === 0){
                    setLoaderOpen(false)
                    setErrorMsg(res.data.message)
                    setEmessage(true);
                    setSmessage(false);
                }

            }).catch((error) => {console.log(error)})
    }
        
        
    const searchFilter = (e) =>{
        if(e === ""){
            setNodata(false)
            loadHospitalTable();
        }else{
            axios.post(`${process.env.REACT_APP_BASE_URL}hospitalSearch/0/10`,{search:e , type:1 , id:id},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
              (responce) => {
                if(responce.data.status === 0){
                    setNodata(true)
                }else if(responce.data.status === 1){
                    setNodata(false);
                  setData(responce.data.details)
                  
                }
              
              }
            ).catch((error) => {console.log(error)})
        }
          
    }

    const DistrictOptions = districts.map(option =><MenuItem key={option._id} value={option._id}>{option.district}</MenuItem>)
    const cityOptions = cities.map(option =><MenuItem key={option._id} value={option._id}>{option.city}</MenuItem>)
    const areaOptions = areas.map(option =><MenuItem key={option._id} value={option._id}>{option.area}</MenuItem>)
    const stateOptions = states.map(option =><MenuItem key={option._id} value={option._id}>{option.state}</MenuItem>)
    
    
    const googleMap = <GoogleMap
        center={center}
        defaultCenter={center}
        zoom={15}
        mapContainerStyle={{ width: '100%', height: '95%' }}
        options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
        }}
        onClick={ev => {
            setCenter({
                lat:ev.latLng.lat(),
                lng:ev.latLng.lng()
            })
            setMarker(ev)
        }}
        >
        <MarkerF position={center}></MarkerF>
    </GoogleMap>




    return(
        <>
        <Grid sx={{mx:3,my:2}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid> 
                    <Typography sx={{fontSize:'18px'}} >Hospitals</Typography>
                </Grid>
                <Grid sx={{ display: 'flex',mb:1 }}>
                    <Grid>
                        <TextField className="text_field" placeholder="search" onChange={e => {searchFilter(e.target.value)}} fullWidth InputProps={{
                            startAdornment: (
                           <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                            
                        ),
                        }}/>
                    </Grid>
                    <Grid  sx={{ display: 'flex',alignItems:'center',mx:3 }}>
                        <Typography className="add_text">Add </Typography>
                        <AddCircleIcon className="Add_icon"  onClick={handleOpen} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <TableContainer>
                    <Table  aria-label="simple table">
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell className="tableHead">S.NO</TableCell>
                                <TableCell className="tableHead">HOSPITAL</TableCell>
                                <TableCell className="tableHead">EMAIL</TableCell>
                                <TableCell className="tableHead">DISTRICT</TableCell>
                                <TableCell className="tableHead">ADDRESS</TableCell>
                                <TableCell className="tableHead">ACTIONS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                          nodata?<TableRow>
                          
                          <TableCell colSpan={8} align="center">
                             <h2> No Hospitals Data Found </h2>
                          </TableCell>
                        
                          </TableRow>:table_row
                          }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid style={{ display: "flex", alignItems:"right", justifyContent:"right"}}>
                    <Stack spacing={2}>
                        <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                    </Stack>
                </Grid>
            </Grid>
        </Grid> 
         {/*Start MOdal */}
         <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
           <Box sx={style}>
                <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName" align="center" variant="h4" gutterBottom component="div">
                        Add hospital details
                    </Typography>
                </Grid>
                <Box sx={{ width: '100%' }} className="modalscroll">
                    <Grid className="modalscrollwidth">
                        <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={6}>
                                <Grid  item sx={{mt:2}}  >
                                    <FormLabel>Hospital</FormLabel>
                                    <TextField name="name" className={'text_field'} {...register('name',{required: true})} />
                                    {errors.name && errors.name.type === "required" && <p className='error mt-0'>Please enter hospital</p>}
                                </Grid>
                                <Grid item sx={{mt:2}}  >
                                    <FormLabel>Email</FormLabel>
                                    <TextField fullWidth name="email" className={'text_field'} {...register('email',{required: true,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} ></TextField>
                                    {errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter email</p>}
                                    {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>please enter valid Email</p>}
                                </Grid>
                                <Grid item sx={{mt:2}}  >
                                    <FormLabel>Phone number</FormLabel>
                                    <TextField className={'text_field'} {...register('phone',{required: true,pattern:/^\d{10}$/})}></TextField>
                                    {errors.phone && errors.phone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                                    {errors.phone && errors.phone.type === "pattern"  && <p className='error mt-0'>please enter valid Number</p>}
                                </Grid>
                                <Grid  item sx={{mt:2}}  >
                                    <FormLabel>State</FormLabel>
                                    <Select
                                    disableUnderline
                                    displayEmpty
                                    className='selectOptions'fullWidth
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={state}
                                    placeolder="Select state"
                                    {...register('state',{required: true})}
                                    onChange={stateChange} >
                                        <MenuItem value="">
                                            <em>Select State</em>
                                        </MenuItem>
                                        {stateOptions}
                                    </Select>
                                    {errors.state && errors.state.type === "required" && <p className='error mt-0'>Please enter state</p>}
                                </Grid>
                              
                            </Grid>
                            <Grid item md={6}>
                                <Grid item sx={{mt:2}}  >
                                    <FormLabel >District</FormLabel>
                                    <Select 
                                    disableUnderline
                                    displayEmpty
                                    className='selectOptions'fullWidth
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={district}
                                    placeolder="Select District"
                                    {...register('district',{required: true})}
                                    onChange={districtChange}
                                    >
                                    <MenuItem value="">
                                        <em>Select district</em>
                                    </MenuItem>
                                    {DistrictOptions}
                                    </Select>
                                    {errors.district && errors.district.type === "required" && <p className='error mt-0'>Please select district</p>}
                                </Grid>
                                <Grid  item sx={{mt:2}}  >
                                    <FormLabel>City/Mandal</FormLabel>
                                    <Tooltip title="If mandal is not available, please select others ">
                                        <InfoSharpIcon className="sharpicon"/>
                                    </Tooltip>
                                    <Select 
                                    disableUnderline
                                    displayEmpty
                                    className='selectOptions'
                                    fullWidth
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={city}
                                    placeolder="Select City/Mandal"
                                    {...register('city',{required: true})}
                                    onChange={cityChange}>
                                        <MenuItem value="">
                                            <em>Select City/Mandal</em>
                                        </MenuItem>
                                        {cityOptions}
                                        <MenuItem value="10">
                                            <em>other City/Mandal</em>
                                        </MenuItem>
                                    </Select>
                                    {errors.city && errors.city.type === "required" && <p className='error mt-0'>Please add city</p>}
                                </Grid>
                                <Grid  item sx={{mt:2}}>
                                    <FormLabel>Other City/Mandal</FormLabel>
                                    <TextField className={'text_field'} disabled={othercity} onChange={(event)=>{setOthercityText(event.target.value)}}/>
                                    {inCityerror && <span class="error">Please enter other City/Mandal</span>}
                                </Grid>
                                {/* <Grid item sx={{mt:2}}  >
                                    <FormLabel>Area</FormLabel>
                                    <Tooltip className="tooltip" title="If area is not available, please select other ">
                                      <InfoSharpIcon className="sharpicon"/>
                                    </Tooltip>
                                    <Select
                                        disableUnderline
                                        displayEmpty
                                        className='selectOptions'fullWidth
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={area}
                                        placeolder="Select Area"
                                        {...register('area',{required: true})}
                                        onChange={areaChange}>
                                            <MenuItem value="">
                                            <em>Select Area</em>
                                        </MenuItem>
                                        <MenuItem value="10">
                                            <em>Other Area</em>
                                        </MenuItem>
                                        {areaOptions}
                                    </Select>
                                    {errors.area && errors.area.type === "required" && <p className='error mt-0'>Please enter area</p>}
                                </Grid> */}
                                {/* <Grid item sx={{mt:2}}  >
                                    <FormLabel>Other Areas</FormLabel>
                                    <TextField className={'text_field'} onChange={(e)=>{setOtherAreaText(e.target.value)}} disabled={other}/>
                                    {inAreaerror && <span class="error">Please enter other Area</span>}
                                </Grid> */}
                                <Grid item sx={{mt:2}}>
                                    <FormLabel>Address</FormLabel>
                                    <Grid item sx={{display:'flex'}}>
                                        <TextField fullWidth value={address}  className="address_field "  multiline variant='outlined' rows="2" size="small"   
                                        {...register('address',{required: true})} 
                                        onChange={(e)=>{setAddress(e.target.value)}}
                                        />
                                        <MyLocationIcon className="locationIcon" onClick={()=>{
                                            handleOpenMap()
                                            setMap()
                                            }}/>
                                    </Grid>
                                    {errors.city && errors.city.type === "required" && <p className='error mt-0'>Please add address</p>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid item sx={{my:3}} >
                            <Button  className="customerLogin"bgColor='#129547' onClick={handleSubmit(onSubmit)}>Add</Button>
                            <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loaderOpen}
                            onClick={handleClose}
                            invisible={true}
                        >
                            <Lottie 
                            options={defaultOptions}
                            height={100}
                            width={100}
                            />
                        </Backdrop>
                        </Grid> 
                    </Grid>
                </Box>
            </Box>
      </Modal>
    {/* End Modal */}
    {/*Start View Modal */}
        <Modal
        open={openView}
        // onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <Box sx={style}>
                <CloseIcon onClick={()=>{handleCloseView()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                        Hospital details
                    </Typography>
                </Grid>
                <Box sx={{ width: '100%' }} className="modalscroll">
                    <Grid className="modalscrollwidth">
                    <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item md={6}>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>Hospital</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'} >{viewHospital.name}</Typography>
                            </Grid>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>Email</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'} >{viewHospital.email}</Typography>
                            </Grid>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>Phone number</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'} >{viewHospital.phoneNumber}</Typography>
                            </Grid>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>Password</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'} >{viewHospital.password}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={6}>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>State</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'}>{viewHospital.state}</Typography>
                            </Grid>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>District</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'}>{viewHospital.district}</Typography>
                            </Grid>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>City</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'}>{viewHospital.city}</Typography>
                            </Grid>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel>Address</FormLabel>
                                <Typography fullWidth name="name" className={'viewBox'} >{viewHospital.address}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid item sx={{my:3}} >
                            <Button  className="customerLogin" bgColor='#129547' onClick={()=>{handleCloseView()}} >
                                Ok
                            </Button>
                        </Grid> 
                    </Grid>
                </Grid>
                </Box>
            </Box>
      </Modal>


{/* End View Modal */}

{/* Start Edit Modal */}
<Modal
        open={openEdit}
        // onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <Box sx={style}>
                <CloseIcon onClick={()=>{handleCloseEdit()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                        Edit hospital details
                    </Typography>
                </Grid>
                <Box sx={{ width: '100%' }} className="modalscroll">
                    <Grid className="modalscrollwidth">
                        <Grid container justifyContent='center' alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={6}>
                                <Grid  item sx={{mt:2}}>
                                    <FormLabel>Hospital</FormLabel>
                                    <TextField name="name" value={viewHospital.name} className={'text_field'}
                                    //{...register2('name',{required: true})}
                                    onChange={(e)=>{setViewHospital({
                                        ...viewHospital,
                                        name:e.target.value
                                    })}}
                                    />
                                    {/* {errors2.name && errors2.name.type === "required" && <p className='error mt-0'>Please enter Name</p>}
                                    {errors2.name && errors2.name.type === "pattern"  && <p className='error mt-0'>please enter valid Name</p>} */}
                                </Grid>
                                <Grid item sx={{mt:2}}>
                                    <FormLabel>Email</FormLabel>
                                    <TextField fullWidth value={viewHospital.email} name="email" className={'text_field'} 
                                    //{...register2('editEmail',{required: true,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                                    onChange={(e)=>{setViewHospital({
                                        ...viewHospital,
                                        email:e.target.value
                                    })}}
                                    ></TextField>
                                    {/* {errors2.editEmail && errors.editEmail.type === "required" && <p className='error mt-0'>Please enter Email</p>}
                                    {errors2.editEmail && errors2.editEmail.type === "pattern"  && <p className='error mt-0'>please enter valid Email</p>} */}
                                </Grid>
                                <Grid item sx={{mt:2}}>
                                    <FormLabel>Phone number</FormLabel>
                                    <TextField value={viewHospital.phoneNumber} className={'text_field'}
                                    // {...register2('editPhone',{required: true,pattern:/^\d{10}$/})}
                                    onChange={(e)=>{setViewHospital({
                                        ...viewHospital,
                                        phoneNumber:e.target.value
                                    })}}
                                    ></TextField>
                                    {/* { viewHospital.phoneNumber === "" && errors2.editPhone && errors2.editPhone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                                    {errors2.editPhone && errors2.editPhone.type === "pattern"  && <p className='error mt-0'>Please enter valid Number</p>} */}
                                </Grid>
                                <Grid item sx={{mt:2}}>
                                    <FormLabel>State</FormLabel>
                                    <Select
                                    disableUnderline
                                    displayEmpty
                                    className='selectOptions'
                                    fullWidth
                                    value={viewHospital.stateId}
                                    placeolder="Select state"
                                    //{...register2('editState')}
                                    onChange={(e)=>{setViewHospital({
                                        ...viewHospital,
                                        stateId:e.target.value,
                                        cityId:"",
                                        districtId:"",
                                        city:"",
                                        district:""
                                        })
                                        stateChange(e)
                                        setHandleStateChange(false);
                                        setHandleDistrictChange(false);
                                    }}
                                    >
                                        <MenuItem value="">
                                            <em>Select State</em>
                                        </MenuItem>
                                        {stateOptions}
                                    </Select>
                                    {/* {errors2.editState && errors2.editState.type === "required" && <p className='error mt-0'>Please enter State</p>} */}
                                </Grid>
                              
                            </Grid>
                            <Grid item md={6}>
                                <Grid  item sx={{mt:2}}>
                                    <FormLabel >District</FormLabel>
                                    <Select
                                    disableUnderline
                                    displayEmpty
                                    className='selectOptions'
                                    fullWidth
                                    value={viewHospital.districtId}
                                    placeolder="Select district"
                                    //{...register2('editDistrict')}
                                    onChange={(e)=>{setViewHospital({
                                        ...viewHospital,
                                        districtId:e.target.value,
                                        cityId:""
                                    })
                                    setHandleDistrictChange(false);
                                    setHandleCityChange(false)
                                    getCities(e.target.value);
                                    }}
                                    >
                                        <MenuItem value="">
                                            <em>Select district</em>
                                        </MenuItem>
                                        {handleStateChange&&<MenuItem value={viewHospital.districtId}>
                                          {viewHospital.district}
                                        </MenuItem>}
                                        {DistrictOptions}
                                    </Select>
                                    {/* {errors2.editDistrict && errors2.editDistrict.type === "required" && <p className='error mt-0'>Please enter District</p>} */}
                                </Grid>
                                <Grid  item sx={{mt:2}}>
                                    <FormLabel>City/Mandal</FormLabel>
                                    <Select
                                    disableUnderline
                                    displayEmpty
                                    className='selectOptions'
                                    fullWidth
                                    value={Boolean(viewHospital.cityId)?viewHospital.cityId:""}
                                    placeolder="Select City/Mandal"
                                    //{...register('editcity',{required: true})}
                                    onChange={(e)=>{
                                        editCityChange(e)
                                        setHandleCityChange(false)
                                        getAreas(e.target.value)
                                    }}
                                    >
                                        <MenuItem value="">
                                        <em>Select City/Mandal</em>
                                        </MenuItem>
                                        {handleDistrictChange&&<MenuItem value={viewHospital.cityId}>
                                          {viewHospital.city}
                                        </MenuItem>}
                                        {cityOptions}
                                        <MenuItem value="10">
                                            <em>other City/Mandal</em>
                                        </MenuItem>
                                    </Select>
                                    {/* {errors2.editcity && errors2.editcity.type === "required" && <p className='error mt-0'>Please enter City</p>} */}
                                </Grid>
                                <Grid  item sx={{mt:2}}>
                                    <FormLabel>Other City/Mandal</FormLabel>
                                    <TextField className={'text_field'} disabled={editothercity} onChange={(e)=>{setViewHospital({
                                        ...viewHospital,
                                        city:e.target.value
                                    })}}/>
                                </Grid>
                                {/* <Grid  item sx={{mt:2}}>
                                    <FormLabel>Area</FormLabel>
                                    <Select
                                    disableUnderline
                                    displayEmpty
                                    className='selectOptions'
                                    fullWidth
                                    value={viewHospital.areaId}
                                    placeolder="Select City/Mandal"
                                    //{...register2('editArea',{required: true})}
                                    onChange={(e)=>{
                                        editAreaChange(e)
                                   }}
                                    >
                                        <MenuItem value="">
                                            <em>Select Area</em>
                                        </MenuItem>
                                        {handleCityChange && <MenuItem value={viewHospital.areaId}>
                                          {viewHospital.area}
                                        </MenuItem>}
                                        <MenuItem value="10">
                                            <em>Other Area</em>
                                        </MenuItem>
                                        {areaOptions}
                                    </Select>
                                  
                                </Grid> */}
                                {/* <Grid item sx={{mt:2}}>
                                    <FormLabel>Other Areas</FormLabel>
                                    <TextField className={'text_field'} disabled={editotherarea} onChange={(e)=>{setViewHospital({
                                        ...viewHospital,
                                        area:e.target.value
                                    })}}/>
                                </Grid> */}
                                <Grid sx={{mt:2}}>
                                    <FormLabel>Address</FormLabel>
                                    <Grid item sx={{display:'flex'}}>
                                        <TextField className="address_field" value={viewHospital.address} multiline variant='outlined' rows={2} 
                                        //{...register('editAddress',{required: true})}
                                        onChange={(e)=>{
                                            setViewHospital({
                                                ...viewHospital,
                                                address:e.target.value
                                            })
                                        }}
                                        />
                                        {/* {errors2.editAddress && errors2.editAddress.type === "required" && <p className='error mt-0'>Please enter Address</p>} */}
                                        <MyLocationIcon className="locationIcon" onClick={()=>{
                                            handleOpenEditMap()
                                            setMap()
                                        }}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid item sx={{my:3}}>
                            <Button className="customerLogin" bgColor='#129547' onClick={()=>{
                                //handleSubmit2(onEditSubmit)
                                onEditSubmit();
                                }
                                    // console.log("hi")
                                }>Update</Button>
                        </Grid> 
                    </Grid>
                </Box>
            </Box>
      </Modal>
{/* End Edit Modal */}


{/*START DELETE MODAL */}
           
    <Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                 Do you want to delete the hospital?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                <Button className="yesButton" 
                onClick={Delete} 
                >Yes</Button>
                <Button className="noButton" onClick={handleCloseDelete}>No</Button>
            </Grid>
        </Box>
    </Modal>

  {/*END DELETE MODAL*/}

  {/*START BLOCK MODAL*/}
  <Modal
        open={openBlock}
        // onClose={handleCloseBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
        <Box sx={DeleteStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Do you want to block the hospital?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                <Button className="yesButton" onClick={Block}>Yes</Button>
                <Button className="noButton" onClick={handleCloseBlock}>No</Button>
            </Grid>
        </Box>
      </Modal>

    {/*END BLOCK MODAL*/}

    {/*START UNBLOCK MODAL*/}
    <Modal
        open={openunBlock}
        // onClose={handleCloseBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
        <Box sx={DeleteStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Do you want to un-block the hospital?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
                <Button className="yesButton" onClick={unBlock}>Yes</Button>
                <Button className="noButton" onClick={handleCloseunBlock}>No</Button>
            </Grid>
        </Box>
    </Modal>

    {/*END UNBLOCK MODAL*/}


    {/*Map modal*/}
    <Modal
        open={openMap}
        // onClose={handleCloseMap}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={MapStyle}>
            <Grid  className="mapHeight">
            {isLoaded? googleMap:<h1>loading...</h1>}
            </Grid>
            <Grid md={12}>
                <Grid>
                    <Typography sx={{color:'#979899'}}>Pin location</Typography>
                </Grid>
                <Grid container sx={{mt:1}}>
                    <Grid md={0.5}>
                        <img src={LocationIcon} alt="icon"/>
                    </Grid>
                    <Grid md={7}>
                        <Typography>{cityName}</Typography>
                        <Typography sx={{color:'#979899'}}>{address}</Typography>
                    </Grid>
                    <Grid md={4.5}>
                        <Button className="confirmLocationbtn" onClick={()=>{handleCloseMap()
                        handleOpen()
                        }}>Confirm Location</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </Modal>

    {/*Edit Map modal*/}
    <Modal
        open={openEditMap}
        // onClose={handleCloseMap}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={MapStyle}>
            <Grid  className="mapHeight">
            {isLoaded? googleMap:<h1>loading...</h1>}
            </Grid>
            <Grid md={12}>
                <Grid>
                    <Typography sx={{color:'#979899'}}>Pin location</Typography>
                </Grid>
                <Grid container sx={{mt:1}}>
                    <Grid md={0.5}>
                        <img src={LocationIcon} alt="icon"/>
                    </Grid>
                    <Grid md={7}>
                        <Typography>{cityName}</Typography>
                        <Typography sx={{color:'#979899'}}>{address}</Typography>
                    </Grid>
                    <Grid md={4.5}>
                        <Button className="confirmLocationbtn" onClick={()=>{
                        setOpenEditMap(false)
                        setOpenEdit(true);
                        }}>Confirm Location</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>   
    </Modal>

    <Modal
        open={approvalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={approvalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Do you really want to Approve?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
                <Button className="yesButton" onClick={()=>approveReject(deleteId, 1)}
                >Yes</Button>
                <Button className="noButton" onClick={approvalHandleClose}>No</Button>
            </Grid>
        </Box>
    </Modal>
    <Modal
        open={rejectOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={approvalStyle}>
            <Typography  id="modal-modal-title" variant="h6" component="h2">
                Do you really want to Reject?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
              <Button className="yesButton" onClick={()=>approveReject(deleteId, 2)} >Yes</Button>
              <Button className="noButton" onClick={rejectHandleClose}>No</Button>
            </Grid>
        </Box>
    </Modal>

        <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
            <Alert onClose={SuccessClose} severity="success" className="snackBar">
                {successMsg}
            </Alert>
        </Snackbar>
        <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
            <Alert onClose={ErrorClose} severity="error" className="snackError">
                {errorMsg}
            </Alert>
        </Snackbar>
    </>

    )
}


export default AdminHospitals