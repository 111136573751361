import React from "react";
import Header from "../../Layouts/header";
import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import SideNav from "../../Layouts/sidenav";
import './Admin.css'
import { Outlet } from "react-router-dom";

const Admin=()=>{
    
    return(
        <>
         
            <Grid>
                <Grid>
                    <Header/>
                </Grid>
                <Grid>
                    <Container className="widthSet">
                        <Grid container sx={{mt:4}}>
                            <Grid item md={2} className="listItem_pad" >
                                <SideNav/>
                            </Grid>
                            <Grid item md={10}  className="listItem_pad dashboard_bg" >
                                <Outlet />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
             </Grid>

        </>
    )
}

export default Admin;