import React from 'react'
import { Grid } from '@mui/material'
import Container from '@mui/material/Container'
import DriverImage from '../../../Assets/icons/driverimge.svg'
import DriverCall from '../../../Assets/icons/drivercall.svg'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'
import { useEffect , useState } from 'react'
import axios from 'axios';
import {useJsApiLoader , GoogleMap , MarkerF } from '@react-google-maps/api';
import PubNub from 'pubnub';
import ambulancetrack from '../../../Assets/icons/ambulancetrack.png'


const Sos=()=>{

    let{ id } = useParams();
    let idparam = id.split('=')
    

    const {isLoaded} = useJsApiLoader({
    googleMapsApiKey : process.env.REACT_APP_GOOGLE_KEY,
    libraries: ['places'],
    })

    const channels = ([idparam[1]]);
    const center = {lat: 17.0226374, lng: 82.2367775}
    const [fromLatLng , setFromLatLng] = useState({lat:"" , lng:""});
    const [toLatLong , setToLatLong] = useState({lat:"" , lng:""});
    const [bookingClose,setBookingClose]=React.useState(true);

    const pubnub =  new PubNub({
        publishKey : "pub-c-d1177e8a-4dd3-4115-ad90-d52fddb4a310",
        subscribeKey : "sub-c-3681dda7-1b85-4efd-9575-724b4a358727",
        uuid: "3b378ddb-3178-4cec-8a5f-a70bb4bdc6dc", //generated uuid,
     });

    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));

    const [trackDetails , setTrackDetails] = useState({
        name:"",
        number:""
    })

   

    useEffect(()=>{
        loadTracking();
        pubnub.subscribe({ channels ,withPresence: true});
        markerSet();
    } , [])

    const markerSet = () =>{
        setInterval(()=>{
            pubnub.history({
                channel:channels,
                reverse:true,
              },
              (status, res) => {
                let count = res.messages;
                let messagesLength = count.length-1;
                // console.log(res.messages[messagesLength].entry.lat, "test1")
                // console.log(res.messages[messagesLength].entry.lng, "test1")
                setFromLatLng({
                    lat:res.messages[messagesLength].entry.lat,
                    lng:res.messages[messagesLength].entry.lng
                })
              })
              axios.post(`${process.env.REACT_APP_BASE_URL}ontheway`,{bookingId:idparam[1] , userId:userlogCheck.id},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    let resData = responce.data.bookingDetails
                   if(resData.dropStatus === 1){
                        setBookingClose(false)
                   }
                }
            ).catch((error) => {console.log(error)})
        },3000)
    }

    const loadTracking=()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}ontheway`,{bookingId:idparam[1] , userId:userlogCheck.id},{headers:{Authorization:localStorage.getItem("Key")}}).then(
            (responce) => {
                let resData = responce.data.bookingDetails
                setTrackDetails({...trackDetails , name:resData.driverName , number:resData.driverPhone })
                setToLatLong({
                    lat:parseFloat(resData.fromLatitude),
                    lng:parseFloat(resData.fromLongitude)
                })
            }
        ).catch((error) => {console.log(error)})
    }

    const googleMap = <GoogleMap
        center={fromLatLng}
        zoom={15}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        options={{
        zoomControl: true,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        }}
    >
    <MarkerF icon={ambulancetrack} position={fromLatLng}></MarkerF>
    <MarkerF position={toLatLong}></MarkerF> 
    
</GoogleMap>


    return(
        <>
        <Container>
          <Grid container sx={{mt:5,mb:5}} className="mapblock">
            <Grid item md={4}>
                <Grid item component="h2">
                    On the way
                </Grid>
                <Grid item container spacing={3} sx={{mt:1}}>
                    <Grid item>
                        <img src={DriverImage} alt="driver"/>
                    </Grid>
                    <Grid item>
                        <Typography fontSize="14px" color="#979899" >Ambulance driver</Typography>
                        <Typography>{trackDetails.name}</Typography>
                    </Grid>
                </Grid>
                <Grid item container spacing={3} sx={{mt:1}}>
                    <Grid item>
                        <img src={DriverCall} alt="driver" className='driverphone'/>
                    </Grid>
                    <Grid item>
                        <Typography fontSize="14px" color="#979899">Ambulance driver number</Typography>
                        <Typography>+91 {trackDetails.number}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {bookingClose?
            <Grid item md={8} className="dashboard_bg trackingHeight">
                {isLoaded? googleMap:<h1>loading...</h1>}
             </Grid>:<Grid item md={8} className="dashboard_bg trackingHeight">
               <Grid  sx={{height:'400px'}} container justifyContent="center" alignItems="center"><h1>Booking Completed</h1></Grid>
             </Grid>}
          </Grid>
        </Container>
        </>
    )
}
export default Sos