import React from "react";
import Header from "../../Layouts/header";
import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import SideNav from "../../Layouts/sidenav";
import { Outlet } from "react-router-dom";

//const Outlet=useOutlet()


const Employee=()=>{
    return(
        <>
            <Grid>
                <Grid>
                    <Header/>
                </Grid>
                <Grid>
                    <Container className="widthSet">
                        <Grid container sx={{mt:4}}>
                            <Grid item className="listItem_pad" md={2}>
                                <SideNav />
                            </Grid>
                            <Grid item className="listItem_pad dashboard_bg" md={10}>
                                <Outlet />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>

        </>
    )
}

export default Employee;