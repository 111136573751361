import React from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
// import IconButton from "@mui/material/IconButton";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import './customer.css'
import axios from "axios";
import { useEffect , useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';


const AdminCustomers=()=>{


   // DELETE MODAL //
   const [open, setOpen] = React.useState(false);
   const [smessage , setSmessage] =useState(false);
   const [emessage , setEmessage] =useState(false);
   const [nodata , setNodata] = useState(false);
   const [page, setPage] = useState(0);
   const [deleteId , setDeleteId] = useState("");
   const [successMsg , setSuccessMsg] = useState("");
   const [errorMsg , setErrorMsg] = useState("");
   const [searchData , setSearchData] = useState("")
   const handleOpen = (id) => {
    setDeleteId(id)
    setOpen(true);
   }
   const Delete = () =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}deleteUser/${deleteId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
          if(responce.data.status === 1){
            setOpen(false);
            loadCustomerTable();
            setSmessage(true);
            setSuccessMsg(responce.data.message)
            searchFilter(searchData)
          }else{
            setOpen(false);
            setEmessage(true);
            setErrorMsg(responce.data.message)
          }
      }
      ).catch((error) => {console.log(error)})

  }
  // const WarningUnClose = () => setWunmessage(false);
   const handleClose = () => setOpen(false);
  // BLOCK MODAL//
   const [openBlock, setOpenBlock] = React.useState(false);
   const [openUnBlock, setOpenUnBlock] = React.useState(false);
   const [blockId , setBlockId] = React.useState("");
   const handleOpenBlock = (id , type) => {
    if(type === "block"){
      setBlockId(id)
      setOpenBlock(true)
    }else if(type === "Unblock"){
      setBlockId(id)
      setOpenUnBlock(true)
    }
    
  };
  

  const Block = () =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}blockUser/${blockId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
          if(responce.data.status === 1){
            handleCloseBlock();
            loadCustomerTable();
            setSmessage(true);
            setSuccessMsg(responce.data.message)
            searchFilter(searchData)
          }else{
            setEmessage(true);
            setErrorMsg(responce.data.message)
          }
      }
  ).catch((error) => {console.log(error)})
  }

  const UnBlock = () =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}blockUser/${blockId}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
      (responce) => {
          if(responce.data.status === 1){
            handleCloseUnBlock();
            loadCustomerTable();
            setSmessage(true);
            setSuccessMsg(responce.data.message);
            searchFilter(searchData);
          }else{
            setEmessage(true);
            setErrorMsg(responce.data.message)
          }
      }
  ).catch((error) => {console.log(error)})
  }

   const handleCloseBlock = () => setOpenBlock(false);
   const handleCloseUnBlock = () => setOpenUnBlock(false);

   const style = {
     position: 'absolute' ,
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 400,
     bgcolor: ' #F1F3F6 !important;',
     border: '2px solid white',
     boxShadow: 24,
     outline:0,
     p: 5,
     textAlign:'center',
     borderRadius:'10px'
   };


    const[data ,setData] = useState([])
    const[vertical] =useState('top');
    const[horizontal] =useState('right');
    const[totalPages , setTotalPages] =useState();
    // let user = JSON.parse(sessionStorage.getItem("userType"));
    // let id = user.id;

    const loadCustomerTable = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}userList/0/10`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
                setData(responce.data.details);
                setTotalPages(responce.data.totalPages)
                
            }
        ).catch((error) => {console.log(error)})
    }

        useEffect(() => { 
            loadCustomerTable()  
        },[]);

      const table_row = data.map((row , index) =>{if(row.blockStatus) { return (<TableRow key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
        <TableCell align="center" component="th" scope="row">{(page)*10+index+1}</TableCell>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell align="center">{row.phone}</TableCell>
        <TableCell align="center">{row.email}</TableCell>
        <TableCell align="center">
            <DeleteOutlineOutlinedIcon onClick={()=>{handleOpen(row._id)}}   className="actioinIcon" sx={{color:'#E11F26',cursor:'pointer'}}/>
            <BlockOutlinedIcon onClick={()=>{handleOpenBlock(row._id , "block")}}  className="actioinIcon" sx={{cursor:'pointer'}}/>
        </TableCell>
      </TableRow>)}else{
          return(<TableRow key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell align="center" component="th" scope="row">{(page)*10+index+1}</TableCell>
            <TableCell align="center">{row.name}</TableCell>
            <TableCell align="center">{row.phone}</TableCell>
            <TableCell align="center">{row.email}</TableCell>
            <TableCell align="center">
                <DeleteOutlineOutlinedIcon onClick={()=>{handleOpen(row._id)}}   className="actioinIcon" sx={{color:'#E11F26'}}/>
                <BlockOutlinedIcon onClick={()=>{handleOpenBlock(row._id , "Unblock")}}  className="actioinIcon" sx={{color:'#A70006',cursor:'pointer'}}/>
            </TableCell>
          
            </TableRow>)
        }
        
      });

        
        // const[wmessage , setWmessage] =useState(false);
        
        const handleChange = (event, value) => {
            setPage(value-1);
            axios.get(`${process.env.REACT_APP_BASE_URL}userList/${value-1}/10`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
                (responce) => {setData(responce.data.details)}
            ).catch((error) => {console.log(error)})
        };
          const ErrorClose = () => {
            setEmessage(false)
          }

          const SuccessClose=() => {
            setSmessage(false);
          }

          // const WarningClose=()=>{
          //   setWmessage(false)
          // }

        const searchFilter = (e) =>{
          setSearchData(e)
          if(e === ""){
            setNodata(false);
            loadCustomerTable();
          }else{
            axios.post(`${process.env.REACT_APP_BASE_URL}userSearch/0/10`,{search:e},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
              (responce) => {
                if(responce.data.status === 0){
                  setNodata(true);
                }else if(responce.data.status === 1){
                  setNodata(false);
                  setData(responce.data.details)
                  setTotalPages(responce.data.totalPages)
                }
              }
          ).catch((error) => {console.log(error)})
          }
          
        }

       
    return(
        <>
        <Grid sx={{mx:3,my:2}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid> 
                    <Typography sx={{fontSize:'18px'}} >Customers</Typography>
                </Grid>
                <Grid sx={{ display: 'flex' , mb:1 }}>
                  <Grid>
                      <TextField className="text_field" placeholder="search" onChange={e => {searchFilter(e.target.value)}} fullWidth InputProps={{
                        startAdornment: (
                        <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                        ),
                      }}/>
                  </Grid>
                </Grid>
            </Grid>
            <Grid>
                <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead className="tableHeader">
                        <TableRow>
                          <TableCell className="tableHead">S.NO</TableCell>
                          <TableCell className="tableHead">NAME</TableCell>
                          <TableCell className="tableHead">PHONE NUMBER</TableCell>
                          <TableCell className="tableHead">EMAIL</TableCell>
                          <TableCell className="tableHead">ACTIONS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{
                        nodata?<TableRow>
                        <TableCell colSpan={8} align="center">
                            <h2> No Data Found</h2>
                        </TableCell>
                      
                        </TableRow>:table_row}
                      </TableBody>
                    </Table>
                </TableContainer>
                <Grid style={{ display: "flex", alignItems: "right", justifyContent:"right", marginTop:"10px"}}>
                    <Stack spacing={2}>
                        <Pagination count={totalPages} page={page+1} onChange={handleChange} className={"paginationAlign"}/>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
        
        <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose} >
          <Alert onClose={SuccessClose} severity="success" >
            {successMsg}
          </Alert>
        </Snackbar> 
        <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
          <Alert onClose={ErrorClose} severity="error" >
            {errorMsg}
          </Alert>
        </Snackbar> 
           
            


{/*START DELETE MODAL */}
           
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Do you want to delete the customer?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
            <Button className="yesButton" onClick={Delete}>Yes</Button>
            <Button className="noButton" onClick={handleClose}>No</Button>
          </Grid>
        </Box>
      </Modal>

    {/*END DELETE MODAL  */}
    
    {/*START BLOCK MODAL  */}
    <Modal
        open={openBlock}
        // onClose={handleCloseBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you want to block the customer?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
              <Button className="yesButton" onClick={Block}>Yes</Button>
              <Button className="noButton" onClick={handleCloseBlock}>No</Button>
            </Grid>
        </Box>
      </Modal>

    {/*END BLOCK MODAL  */}
    {/*START UNBLOCK MODAL  */}
    <Modal
        open={openUnBlock}
        // onClose={handleCloseUnBlock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to Un-block the customer?
          </Typography>
          <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
            <Button className="yesButton" onClick={UnBlock}>Yes</Button>
            <Button className="noButton" onClick={handleCloseUnBlock}>No</Button>
          </Grid>
        </Box>
    </Modal>

    {/*END UNBLOCK MODAL  */}
        </>
    )
}

export default AdminCustomers