import Container from '@mui/system/Container';
import { Typography } from 'ui-neumorphism';
import { Grid , List , ListItem } from '@mui/material';
import { Box } from '@mui/system'
import '../AboutUs/aboutus.css';

const Terms = () => {
    return(
        <>
            <Container className='widthSet'>
                <Box sx={{ flexGrow: 1,mt:5,mb:10 }}>
                    <Grid container>
                        <Grid className="aboutresponsive" item md={12} sm={12}>
                            <Grid sx={{mb:2 }} component={'h1'} gutterBottom >Terms and Conditions</Grid>
                            <Typography className="dataContent">
                                GoAmbee is an ambulance booking service provider and all the information, content, materials are legally amended and are bound to the terms between the users and the application. If you are using the application then you are legally bound to all the terms and conditions. If you do not agree with any part of these Terms, please refrain from using the application.
                                    <List>
                                        <ListItem sx={{ fontWeight: 'bold' }}>
                                            1. Information We Collect
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            1.1 Eligibility:-
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            By using the Website/Application, you represent and warrant that you are capable and have the legal capacity to enter into a binding contract. If you are using the Website/Application on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            1.2 Ambulance Booking Process:-
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            Our Website/Application provides a platform to facilitate ambulance bookings. We do not own or operate the ambulance services but connect you with third-party ambulance service providers or emergency medical personnel. Any bookings made through the Website/Application are subject to the terms and conditions of the respective service providers.
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem sx={{ fontWeight: 'bold' }}>
                                            2. User Conduct
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            2.1 Acceptable Use:-
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            You agree to use the Website/Application only for lawful purposes and in compliance with these Terms. You shall not:
                                                <ListItem>
                                                    * Violate any applicable laws, regulations, or third-party rights.
                                                </ListItem>
                                                <ListItem>
                                                    * Engage in fraudulent, abusive, or deceptive practices.
                                                </ListItem>
                                                <ListItem>
                                                    * Upload, transmit, or distribute any harmful or malicious content that may damage the Website or interfere with its operation.
                                                </ListItem>
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            2.2 Account Responsibility:
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            If you create an account on the Website/Application, you are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use or suspected breach of security.
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem sx={{ fontWeight: 'bold' }}>
                                            3. Intellectual Property
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            3.1 Ownership:-
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            The Website/Application, including all its content and intellectual property rights, is owned by us or our licensors. You acknowledge and agree that you do not acquire any ownership or rights by using the Website/Application.
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            3.2 Limited License:-
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable license to access and use the Website/Application solely for personal, non-commercial purposes.
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem sx={{ fontWeight: 'bold' }}>
                                            4. Disclaimer of Warranties
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            4.1 Website/Mobile Application Availability:
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            We strive to ensure that the Website/Mobile Application is accessible and functions properly at all times. However, we do not guarantee uninterrupted availability.
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            4.2 Third-Party Services:
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            The Website/Application may include links or integration with third-party websites, applications, or services. We do not endorse or assume any responsibility for the content, practices of these third-party services.
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem sx={{ fontWeight: 'bold' }}>
                                            5. Limitation of Liability
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            To the fullest extent permitted by applicable law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the Website/Application. This limitation of liability applies regardless of the legal theory under which such damages are sought.
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem sx={{ fontWeight: 'bold' }}>
                                            6. Indemnification
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            You agree to indemnify, defend, and hold us harmless from and against any claims, liabilities,damages, losses, costs, or expenses arising out of or in connection with your use of the Website/Application, violation of these Terms, or nfringement of any rights of third parties.
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem sx={{ fontWeight: 'bold' }}>
                                            7. Governing Law and Jurisdiction
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            These Terms shall be governed by and construed in accordance with the laws. Any dispute arising out of or in connection with these Terms shall be submitted to the exclusive jurisdiction of the courts.
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem sx={{ fontWeight: 'bold' }}>
                                            8. Changes to Terms and Conditions
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            We reserve the right to modify or update these Terms at any time without prior notice. Any changes will be effective upon posting the updated Terms on the Website/Application. It is your responsibility to review the Terms periodically for any changes.
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem sx={{ fontWeight: 'bold' }}>
                                            9. Contact Us
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' , pl : 5}}>
                                            If you have any questions, comments, or concerns regarding these Terms, please contact us.
                                        </ListItem>
                                    </List>
                                    </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default Terms;