import React from 'react'
import CustomerHeader from './Header/header'
import CustomerFooter from './Footer/footer'
import { Outlet } from 'react-router-dom'

const User=()=>{
    return(
        <>
        <CustomerHeader/>
         <Outlet/>
        <CustomerFooter/>
        </>
    )
}
export default User