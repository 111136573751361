import React, { useEffect } from 'react'
import { Grid,Typography } from '@mui/material'
import PriseEdit from '../../../Assets/icons/basepriseedit.svg'
import {   useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';


const OtherPrice=()=>{
    const [edit,setEdit]=useState(true);
    const [makeEditOpt , setMakeEditOpt] = useState(true);
    const [makeEditCls , setMakeEditcls] = useState('textField');
    const [buttonDis , setButtonDis] = useState('none');
    const [smessage , setSmessage] = useState(false);
    const [emessage , setEmessage] = useState(false);
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const {register,handleSubmit,formState: { errors }} = useForm();
    const [successMsg , setSuccessMsg] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [priceObj , setPriceObj] = useState({
        id:'',
        icuBaseFairKm : '',
        icuBaseFair:'',
        icuPricePerKm:'',
        semiIcuBaseFairKm : '',
        semiIcuBaseFair:'',
        semiIcuPricePerKm:'',
        generalBaseFairKm : '',
        generalBaseFair:'',
        generalPricePerKm:''
    })

    const ErrorClose = () => {
        setEmessage(false)
    }
    const SuccessClose=() => {
        setSmessage(false);
    }

    const getPriceList = () =>{
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BASE_URL}getOtherLocPriceList`,
            headers: {
              Authorization: process.env.REACT_APP_AUTHORIZATION,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                const res = response.data.details;
                
                setPriceObj({
                    ...priceObj,
                    id:res._id,
                    icuBaseFairKm : res.icubasefarekm,
                    icuBaseFair:res.icubasefare,
                    icuPricePerKm:res.icuperkm,
                    semiIcuBaseFairKm : res.semiicubasefarekm,
                    semiIcuBaseFair:res.semiicubasefare,
                    semiIcuPricePerKm:res.semiicuperkm,
                    generalBaseFairKm : res.generalbasefarekm,
                    generalBaseFair:res.generalbasefare,
                    generalPricePerKm:res.generalperkm
                })
              }
            })
            .catch((error) => {
              console.log(error);
            });
    }

    const updatePriceList = () =>{
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}editOtherLocPrice`,
            headers: {
              Authorization: process.env.REACT_APP_AUTHORIZATION,
            },
            data:{
                id:priceObj.id,
                icukm:priceObj.icuBaseFairKm,
                icufare:priceObj.icuBaseFair,
                icuperkm:priceObj.icuPricePerKm,
                semiicukm:priceObj.semiIcuBaseFairKm,
                semiicufare:priceObj.semiIcuBaseFair,
                semiicuperkm:priceObj.semiIcuPricePerKm,
                basickm:priceObj.generalBaseFairKm,
                basicfare:priceObj.generalBaseFair,
                basicperkm:priceObj.generalPricePerKm,
            }
          })
            .then((response) => {
              if (response.status === 200) {
                setMakeEditOpt(true);
                setMakeEditcls('textField');
                setButtonDis('none');
                setEdit(true)
                setSmessage(true);
                setSuccessMsg(response.data.message)
              }else{
                setEmessage(true);
                setErrorMsg(response.data.message)
              }
            })
            .catch((error) => {
              console.log(error);
            });
    }

    
    const makeEdit = () =>{
        
        setMakeEditOpt(false);
        setMakeEditcls('');
        setButtonDis('block');
        setEdit(false)
      }

    useEffect(()=>{
        getPriceList()
    },[])
     
    return(
        <>
            <Grid sx={{mx:3,my:2}}>
                <Grid container direction="row" alignItems={"center"}>
                    <Grid item md={6}> 
                        <Typography sx={{fontSize:'18px'}} >Other prices</Typography>
                    </Grid>
                    <Grid item md={1}> 
                        {edit && <Grid item md={12} onClick={makeEdit} className="editBtn">
                                    <img src={PriseEdit} alt="icon"/>&nbsp;&nbsp;&nbsp;&nbsp;Edit
                                </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid md={6} sx={{ mt:5 }}>
                    <Grid container item md={12} sx={{my:3}} justifyContent='center' alignItems="center">
                        <Grid item md={4}>
                        {/* <FormLabel>Driver name</FormLabel> */}
                        </Grid>
                        <Grid item md={2.5}>
                            <Typography className="price_base">BASE FARE KM</Typography>
                        </Grid>
                        <Grid item md={2.5}>
                            <Typography className="price_base">BASE FARE</Typography>
                        </Grid>
                        <Grid item md={2.5}>
                            <Typography className="price_base">PRICE PER KM</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item md={12} alignItems="center" rowSpacing={3} columnSpacing={5}>
                            <Grid sx={{textAlign:"center"}}  item md={4}>
                                <FormLabel className='lableName'>ICU</FormLabel>
                            </Grid>
                            <Grid  item md={2.5}>
                                <TextField name="icuBaseFairKm" type={'number'} value={priceObj.icuBaseFairKm}
                                className={`text_field ${makeEditCls}`}
                                {...register('icuBaseFairKm',{required: !Boolean(priceObj.icuBaseFairKm)})}
                                onChange={(e)=>{setPriceObj({...priceObj , icuBaseFairKm:e.target.value})}}
                                disabled={makeEditOpt}
                                />
                            {errors.icuBaseFairKm && priceObj.icuBaseFairKm ==="" && errors.icuBaseFairKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                            </Grid>
                            <Grid  item md={2.5}>
                            <TextField name="icuBaseFair" type={'number'} value={priceObj.icuBaseFair} className={`text_field ${makeEditCls}`} 
                            {...register('icuBaseFair',{required: !Boolean(priceObj.icuBaseFair)})}
                            onChange={(e)=>{setPriceObj({...priceObj , icuBaseFair:e.target.value})}}
                            disabled={makeEditOpt}
                            />
                            {errors.icuBaseFare && priceObj.icuBaseFair ==="" && errors.icuBaseFare.type === "required" && <p className='error mt-0'>Please enter value</p>}
                            </Grid>
                            <Grid  item md={2.5}>
                            <TextField name="icuPricePerKm" type={'number'} value={priceObj.icuPricePerKm}  className={`text_field ${makeEditCls}`}
                            {...register('icuPricePerKm',{required: !Boolean(priceObj.icuPricePerKm)})}
                            onChange={(e)=>{setPriceObj({...priceObj , icuPricePerKm:e.target.value})}}
                            disabled={makeEditOpt}
                            />
                            {errors.icuPricePerKm && priceObj.icuPricePerKm ===""  && errors.icuPricePerKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                            </Grid>
                    </Grid>
                    <Grid container item md={12} alignItems="center" rowSpacing={3} columnSpacing={5}>
                        <Grid sx={{textAlign:"center"}} item md={4} >
                            <FormLabel className='lableName'>Semi ICU</FormLabel>
                        </Grid>
                        <Grid  item md={2.5}>
                        <TextField name="semiIcuBaseFairKm" type={'number'} value={priceObj.semiIcuBaseFairKm}  className={`text_field ${makeEditCls}`} 
                        {...register('semiIcuBaseFairKm',{required: !Boolean(priceObj.semiIcuBaseFairKm)})}
                        onChange={(e)=>{setPriceObj({...priceObj , semiIcuBaseFairKm:e.target.value})}}
                        disabled={makeEditOpt}
                        />
                        {errors.SemiicuBaseFareKm && priceObj.semiIcuBaseFairKm ==="" && errors.SemiicuBaseFareKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                        </Grid>
                        <Grid  item md={2.5}>
                        <TextField name="semiIcuBaseFair" type={'number'} value={priceObj.semiIcuBaseFair}  className={`text_field ${makeEditCls}`} 
                        {...register('semiIcuBaseFair',{required: !Boolean(priceObj.semiIcuBaseFair)})}
                        onChange={(e)=>{setPriceObj({...priceObj , semiIcuBaseFair:e.target.value})}}
                        disabled={makeEditOpt}
                        />
                        {errors.semiIcuBaseFair && priceObj.semiIcuBaseFair ==="" && errors.semiIcuBaseFair.type === "required" && <p className='error mt-0'>Please enter value</p>}
                        </Grid>
                        <Grid item md={2.5}>
                            <TextField name="semiIcuPricePerKm" type={'number'} value={priceObj.semiIcuPricePerKm}  className={`text_field ${makeEditCls}`}
                            {...register('semiIcuPricePerKm',{required: !Boolean(priceObj.semiIcuPricePerKm)})} 
                            onChange={(e)=>{setPriceObj({...priceObj , semiIcuPricePerKm:e.target.value})}}
                            disabled={makeEditOpt}
                            />
                            {errors.semiIcuPricePerKm && priceObj.semiIcuPricePerKm ==="" && errors.semiIcuPricePerKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                        </Grid>
                    </Grid>
                    <Grid container item md={12} alignItems="center" rowSpacing={1} columnSpacing={5}>
                        <Grid sx={{textAlign:"center"}}  item md={4}>
                            <FormLabel className='lableName'>General</FormLabel>
                        </Grid>
                        <Grid item md={2.5}>
                            <TextField name="GeneralBaseFairKm" type={'number'} value={priceObj.generalBaseFairKm} className={`text_field ${makeEditCls}`}
                            {...register('GeneralBaseFairKm',{required: !Boolean(priceObj.generalBaseFairKm)})} 
                            onChange={(e)=>{setPriceObj({...priceObj , generalBaseFairKm:e.target.value})}}
                            disabled={makeEditOpt}
                            />
                            {errors.GeneralBaseFairKm && priceObj.generalBaseFairKm ==="" && errors.GeneralBaseFairKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                        </Grid>
                        <Grid item md={2.5}>
                            <TextField name="GeneralBaseFair" type={'number'} value={priceObj.generalBaseFair} className={`text_field ${makeEditCls}`}
                            {...register('GeneralBaseFair',{required: !Boolean(priceObj.generalBaseFair)})}  
                            onChange={(e)=>{setPriceObj({...priceObj , generalBaseFair:e.target.value})}}
                            disabled={makeEditOpt}
                            />
                            {errors.GeneralBaseFair && priceObj.generalBaseFair ==="" && errors.GeneralBaseFair.type === "required" && <p className='error mt-0'>Please enter value</p>}
                        </Grid>
                        <Grid item md={2.5}>
                            <TextField name="GeneralPricePerKm" type={'number'} value={priceObj.generalPricePerKm} className={`text_field ${makeEditCls}`}
                            {...register('GeneralPricePerKm',{required: !Boolean(priceObj.generalPricePerKm)})}
                            onChange={(e)=>{setPriceObj({...priceObj , generalPricePerKm:e.target.value})}}
                            disabled={makeEditOpt}
                            />
                            {errors.GeneralPricePerKm && priceObj.generalPricePerKm ==="" && errors.GeneralPricePerKm.type === "required" && <p className='error mt-0'>Please enter value</p>}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid item sx={{my:3}} >
                            <Button style={{display:`${buttonDis}`}} className="customerLogin"bgColor='#129547' onClick={handleSubmit(updatePriceList)} >Update</Button>
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
                <Alert onClose={SuccessClose} severity="success" className="snackBar">
                    {successMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
                <Alert onClose={ErrorClose} severity="error" className="snackError">
                    {errorMsg}
                </Alert>
            </Snackbar>
        
        </>
    )
}
export default OtherPrice