import React from "react";
import Card from '@mui/material/Card';
import Logo from '../../../Assets/icons/header_logo.svg'
import './login.css'
import { Container } from "@mui/system";
import {  FormLabel } from "@mui/material";
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import { Button } from 'ui-neumorphism'
import Grid from '@mui/material/Grid';
import 'ui-neumorphism/dist/index.css';
import { useForm } from 'react-hook-form';
import axios from "axios";
import { useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Lottie from 'react-lottie'
import Backdrop from "@mui/material/Backdrop";
import AlertJson from '../../../Assets/icons/alert.json'
import { useNavigate } from "react-router-dom";



const  Forgot = ()=> {

    const [open, setOpen] = React.useState(false);
    const [sopen, setSopen] = React.useState(false);
    const[vertical] =useState('top');
    const[horizontal] =useState('right');
    const [loaderOpen, setLoaderOpen] = React.useState(false);

    const defaultOptions = {
        loop: true,
       autoplay: true,
       animationData: AlertJson,
       renderer: 'svg'
      }
    const navigate = useNavigate();


    function handleClose() {
        
        setSopen(false);
        setOpen(false);
    }

    const {register,handleSubmit,formState: { errors }} = useForm();
    
    const onSubmit = async (data)=>{
        setLoaderOpen(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}forgotPassword`,{email:data.email},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            console.log(res.data)
                let status = res.data.status;
                if(status === 1){
                    setLoaderOpen(false)
                    setOpen(true);
                    setTimeout(()=>{
                        navigate('/adminLogin');
                    },3000)
                }else if(status === 0){
                    setLoaderOpen(false)
                    setSopen(true);
                }

            }).catch((error) => {console.log(error)})
       }

    return (
        <Container fixed className="login_font">
            <Grid container>
                <Card className={"logo_card logo_img "}>
                    <img src={Logo} alt="logo" />
                </Card>
            </Grid>

            <Grid>
                <Typography  sx={{my:3}} align="center" variant="h4" gutterBottom component="div">
                    Forgot Password
                </Typography>
            </Grid>

         <Grid container  justifyContent='center' lg={12}>
            <Grid container  justifyContent='center' lg={6}>
                <Card className={"login_form"} >
                    <Typography className="forgot_text"  sx={{my:5}} align="center" variant="h6" gutterBottom component="div">
                    An email will be sent to you with
                    a link to login your password
                    </Typography>
                <Grid container justifyContent='center' sx={{mt:3}}>
                    <Grid item   >
                        <FormLabel sx={{color:"black"}}>Email ID</FormLabel>
                        <TextField className={'text_field'} name="email" type="email" placeholder='Enter email'{...register('email',{required: true,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} ></TextField>
                                {errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter email</p>}
                                {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>please enter valid Email</p>}
                    </Grid>
                    </Grid>
                   
                   
                    <Grid container justifyContent='center'>
                        <Grid item sx={{my:5}} >
                            <Button  className="login_btn px-5" color='white' bgColor='#129547' fullwidth variant='contained' onClick={handleSubmit(onSubmit)}>Send</Button>
                        </Grid>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loaderOpen}
                            onClick={handleClose}
                            invisible={true}
                        >
                            <Lottie 
                            options={defaultOptions}
                            height={100}
                            width={100}
                            />
                        </Backdrop>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
        <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" className="snackBar">
                Reset password link has been sent to your Email
            </Alert>
        </Snackbar>
        <Snackbar open={sopen} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error"  className="snackError">
            Invalid Email
            </Alert>
        </Snackbar>

        </Container>
    )
}

export default Forgot;