import React from 'react'
import './header.css'
import { Grid  , Avatar} from '@mui/material';
import Logo from "../../../Assets/icons/header_logo.svg"
import Container from "@mui/material/Container";
import NormalSos from '../../../Assets/icons/normalsos.svg'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AvatarImg from '../../../Assets/icons/avatar.svg'
import OTPInput from "otp-input-react";
import { useState , useEffect,useRef } from "react";
import { useForm } from 'react-hook-form';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import ProfileIcon from '../../../Assets/icons/profileIcon.svg'
import ChangeIcon from '../../../Assets/icons/carbon_password.svg'
import PhoneIcon from '../../../Assets/icons/phoneicon.svg'
import HistoryIcon from '../../../Assets/icons/carbon_history.svg'
import LogoutIcon from '../../../Assets/icons/carbon_logout.svg'
import Profile from '../../../Assets/icons/profile.svg'
import { NavLink } from 'react-router-dom';
import UploadProfileIcon from '../../../Assets/icons/uploadprofile.svg'
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import SosJson from '../../../Assets/icons/sos.json'
import Lottie from 'react-lottie'
import LinearProgress from '@mui/material/LinearProgress';
import Rader from '../../../Assets/icons/Rader.json';
import noambavailable from '../../../Assets/icons/noambavailable.json'
import Geocode from "react-geocode";
import Backdrop from "@mui/material/Backdrop";
import AlertJson from '../../../Assets/icons/alert.json'
import AddressImg from '../../../Assets/icons/address.png'
import MyLocationIcon from '@mui/icons-material/MyLocation';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {useJsApiLoader , GoogleMap , MarkerF } from '@react-google-maps/api';
import LocationIcon from '../../../Assets/icons/location.svg'
import savedAddressServices from '../srvices';
import {Autocomplete} from '@react-google-maps/api'
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from 'react-router-dom';

const CustomerHeader=()=>{
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [BookingModalopen, setBookingModalOpen] = useState(false);
    const [anchorElProfile, setAnchorElProfile] = useState(null);
    const openProfile = Boolean(anchorElProfile);
    const [address , setAddress] = useState("")
    const [sorryScreen , setSorryScreen] = useState(true)
    const [vertical] = useState('top');
    const [horizontal] = useState('right');
    const [updateButton , setUpdateButton] = useState(false)
    const [eyeIcon,setEyeIcon] = useState(false);
    const [passEye,setPassEye] = useState(false);
    const [passEye1,setPassEye1] = useState(false);
    const [passEye2,setPassEye2] = useState(false)
    const [regeyeIcon,setRegeyeIcon] = useState(false);
    const [neweyeIcon,setNeweyeIcon] = useState(false);
    const [checkeyeIcon,setCheckeyeIcon] = useState(false);
    const [type,setType] = useState('password');
    const [passtype,SetPassType] = useState('password');
    const [passtype1,SetPassType1] = useState('password');
    const [passtype2,SetPassType2] = useState('password');
    const [regtype,setRegtype] = useState('password');
    const [newtype,setNewtype] = useState('password');
    const [checktype,setChecktype] = useState('password');
    const [regVerify , setRegverify] = useState(false);
    const [forgotPhone , setForgorPhone] = useState('');
    const [phoneError , setPhoneError] = useState(false);
    const [forgotPhnSuccess ,setForgotPhnSuccess] = useState(false);
    const [invalid , setInvalid] = useState(false);
    const [forgotPhnerror ,setForgotPhnerror] = useState(false);
    const [npassword , setNpassword] = useState(false);
    const [cpassword , setCpassword] = useState(false);
    const [compPassword , setCompPassword] = useState(false);
    const [resetError , setResetError] = useState(false);
    const [resetSuccess , setResetSuccess] = useState(false);
    const [forgotOtpError , setForgotOtpError] = useState(false);
    const [regOtpError , setRegOtpError] = useState(false);
    const [resendSuccess , setResendSuccess] = useState(false);
    const [newPassword ,setNewPassword] = useState("");
    const [checkPassword ,setCheckPassword] = useState("");
    const [userPhone , setUserPhone] = useState("");
    const [logPhone , setLogPhone] = useState("");
    const [logCheck , setLogCheck] = useState("");
    const [soslogCheck , setSosLogCheck] = useState("");
    const [logPhoneCheck , setLogPhoneCheck] = useState(false);
    const [logPassCheck , setLogPassCheck] = useState(false);
    const [phoneExist , setPhoneExist] = useState(false);
    const [logPassword , setLogPassword] = useState("");
    const [logPassCheckEmpty , setLogPassCheckEmpty] = useState(false)
    const [logCheckEmpty , setLogCheckEmpty] = useState(false)
    const [userName , setUserName] = useState("")
    const [homeClass , setHomeclass] = useState(false)
    const [logOutopen , setLogOutopen] = useState(false)
    const [forgotphoneError , setForgotphoneError] = useState(false)
    const [errorMsg , setErrorMsg] = useState("")
    const [makeEditCls , setMakeEditcls] = useState('textField')
    const [makeAddressCls, setMakeAddressCls] = useState('addressField')
    const [inputdis,setInputdis] = useState(true)
    const [openUserChangeNumber, setOpenUserChangeNumber] = useState(false);
    const [savedAddress,setSavedAddress] = useState(false)
    const [openUserChangePassword, setOpenUserChangePassword] = useState(false);
    const [openForgot, setOpenForgot] = useState(false);
    const [openOtp, setOpenOtp] = useState(false);
    const [editBtn, setEditBtn] = useState(true)
    const [changeNumberOtp, setChangeNumberOtp] = useState(false)
    const [openRegister, setOpenRegister] = useState(false);
    const [openReset, setOpenReset] = useState(false);
    const [otpVerify, setotpVerify] = useState(false);
    const [openRegisterOtp, setOpenRegisterOtp] = useState(false);
    const [OTP, setOTP] = useState("");
    const [registrationOTP, setRegistrationOTP] = useState("");
    const [openUserProfile, setOpenUserProfile] = useState(false);
    const [open, setOpen] = useState(false);
    const [userImage, setUserImage] = useState("")
    const [passconError, setPassconError] = useState(false)
    const [changePhn, setChangePhn] = useState("")
    const [smessage, setSmessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [emessage, setEmessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [addPageNum, setAddPageNum] = useState('0');
    const [addPageSize, setAddPageSize] = useState('10');
    const [savAdd, setSavAdd] = useState([]);
    const {register,handleSubmit,formState: { errors }} = useForm();
    const [secureKey, setSecureKey] = useState("")
    const {register:register2,handleSubmit:handleSubmit2,formState: { errors:errors2 }} = useForm();
    const {register:register3,handleSubmit:handleSubmit3,formState: { errors:errors3 }} = useForm();
    const {register:register4,handleSubmit:handleSubmit4,formState: { errors:errors4 }} = useForm();
    const [center , setCenter] = useState({lat:"", lng:""})
    const[cityName , setCityName] = useState("")
    const[latlong , setLatlong] = useState({latitude:"" , longitude:""})

    const location = useLocation();

    const [changePassword , setChangePassword] = useState({
        oldPassword:"",
        newPassword:"",
        confirmPassword:""
    })
    
    const [imageData, setImageData] = useState({
        profile:"",
        name:"",
        preview:"",
        number:"",
        email:"",
        address:""
    });

    const [addAddress , setAddAddress] = useState(
        {
            addresstitle:'',
            address:'',
        }
    )
    
    const userlogCheck = JSON.parse(sessionStorage.getItem("userType"));
    const authKey = localStorage.getItem("Key");

    let password = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/;
    let phone = /^\d{10}$/;
    const navigate =  useNavigate();

    const style = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: '#F1F3F6',
        border: '2px solid white !important',
        outline:'none',
        p: '2% 2% 2% 2%',
        borderRadius: '30px',
    };
    const AddressStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        height:400,
        bgcolor: '#F1F3F6',
        border: '2px solid white !important',
        outline:'none',
        p: '2% 2% 2% 2%',
        borderRadius: '30px',
    };

    const progressStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: '2% 4% 2% 4%',
        outline:0,
        borderRadius:'10px'
    };

    const LogOutstyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: 5,
        textAlign:'center',
        borderRadius:'10px'
    };


    useEffect(() => { 
        logging() 
        getAddress();
        trackStatus();
        getSavedAddresses();
    },[]);


    const handleClickProfile = (event) => {setAnchorElProfile(event.currentTarget)};
    const handleCloseProfile = () => {setAnchorElProfile(null)};
    const BookingModalhandleClose = () => setBookingModalOpen(false);
    const LogOuthandleClose = () => {setLogOutopen(false)}
    const handleClose = () => setOpen(false);
    const handleCloseOtp = () => setOpenOtp(false);
    const handleCloseChangeNumberOtp = () => setChangeNumberOtp(false);
    const handleCloseRegister = () => setOpenRegister(false);
    const handleCloseReset = () => setOpenReset(false);
    const handleCloseRegisterOtp = () => setOpenRegisterOtp(false);
    const handleCloseUserProfile = () => setOpenUserProfile(false);
    const handleCloseUserChangePassword = () => setOpenUserChangePassword(false);
    const handleCloseUserChangeNumber = () => setOpenUserChangeNumber(false);
    const handleCloseSavedPassword = () => setSavedAddress(false)
    const ErrorClose = () => setEmessage(false);
    const SuccessClose=() => setSmessage(false);

    // Forgot//
    const handleCloseForgot = () => setOpenForgot(false);


    const getSavedAddresses =async () => {

        const data = await savedAddressServices.getSavAddresses(addPageNum , addPageSize , userlogCheck?.id)
                if(data){
                    setSavAdd(data)
                }else {
                    setSavAdd([]);
                }
    }

    const handleOpenUserProfile = () =>{
        setOpenUserProfile(true);
        getUserProfile();
        setEditBtn(true);
    }

    const changeUserPassword = (Form_data) => {
        if(Form_data.newPassword !== Form_data.conformPassword){
            setPassconError(true)
        }else{
            setPassconError(false)
            axios.post(`${process.env.REACT_APP_BASE_URL}changePassword`,{userId:userlogCheck?.id , oldPass: Form_data.oldPassword, newPass:Form_data.newPassword , loginType:1},{headers:{Authorization:localStorage.getItem("Key")}}).then((res) => {
                let status = res.data.status;
                if(status === 1){
                    setSuccessMessage(res.data.message)
                    setSmessage(true);
                    handleCloseUserChangePassword()
                }else if(status === 0){
                    setErrorMessage(res.data.message)
                    setEmessage(true)
                }
            }).catch((error) => {if(error.response.status === 401){
                setErrorMessage("Your acoount was logged in from another device")
                setEmessage(true)
                setTimeout(() => {
                    userLogOut();
                }, 2000);
                
            }})
        }
    }

    const [titleError , setTitleError] = useState(false);
    const [addressError , setAddressError] = useState(false);

    const deleteAddress = async(id) => {
        const data = await savedAddressServices.deleteSavedLoc(userlogCheck?.id , id)
        if(data.status == 1){
            getSavedAddresses()
            setSuccessMessage(data.message)
            setSmessage(true);
        }
    }

    const [addLatLng , setAddLatLng] = useState({
        lat:'',
        lng:''
    })


    const addNewAddress = async() => {
        if(addAddress.addresstitle === ''){
            setTitleError(true);
            return;
        }else if(addAddress.address === ''){
            setTitleError(false);
            setAddressError(true);
            return;
        }else{
            setTitleError(false);
            setAddressError(false);
        }
        // navigator.geolocation.getCurrentPosition(async(position)=>{
        //     const data = await savedAddressServices.addSavedLoc(userlogCheck.id , addAddress.addresstitle , addAddress.address , position.coords.latitude ,position.coords.longitude)
        //     if(data.status == 1){
        //         getSavedAddresses()
        //         setAddAddress({...addAddress , addresstitle:'' , address:''})
        //         setSuccessMessage(data.message)
        //         setSmessage(true);
        //     }
        // })
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.setLanguage("en");
       await Geocode.fromAddress(addAddress.address).then(
            async(response) => {
                const { lat, lng } = response.results[0].geometry.location;
                const data = await savedAddressServices.addSavedLoc(userlogCheck?.id , addAddress.addresstitle , addAddress.address , lat ,lng)
                if(data.status == 1){
                    getSavedAddresses()
                    setAddAddress({...addAddress , addresstitle:'' , address:''})
                    setSuccessMessage(data.message)
                    setSmessage(true);
                }
            },
            (error) => {console.log(error)}
        );

    }

    const changePhoneNumber = (Form_data) => {
        setChangePhn(Form_data.phone)
        axios.post(`${process.env.REACT_APP_BASE_URL}changePhone`,{userId:userlogCheck?.id , phone: Form_data.phone, loginType:1},{headers:{Authorization:localStorage.getItem("Key")}}).then((res) => {
            let status = res.data.status;
            if(status === 1){
                setSuccessMessage(res.data.message)
                    setSmessage(true);
                setChangeNumberOtp(true)
                setOpenUserChangeNumber(false)
            }else if(status === 0){
                setErrorMessage(res.data.message)
                    setEmessage(true)
            }
        }).catch((error) => {
            if(error.response.status === 401){
            setErrorMessage("Your acoount was logged in from another device")
            setEmessage(true)
            setTimeout(() => {
                userLogOut();
            }, 2000);
            
        }})
    }

    const changePhoneNumberOtp = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}phnVerifyOtp`,{userId:userlogCheck?.id , phone: changePhn,otp:OTP, loginType:1 },{headers:{Authorization:localStorage.getItem("Key")}}).then((res) => {
            let status = res.data.status;
            if(status === 1){
                setChangeNumberOtp(false)
                setSuccessMessage(res.data.message)
                setSmessage(true);
            }else if(status === 0){
                setErrorMessage(res.data.message)
                setEmessage(true)
            }
        }).catch((error) => {console.log(error)})
    }

    const setPicture = (e) => {
        setImageData({
            ...imageData,
            profile:e.target.files[0],
            preview:URL.createObjectURL(e.target.files[0])
        })
        setUserImage(URL.createObjectURL(e.target.files[0]));
        const formData = new FormData();
            formData.append("profile", e.target.files[0]);
            formData.append("userId", userlogCheck?.id);
            formData.append("loginType", 1);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}updateProfileImage`,
                headers: {
                    Authorization: localStorage.getItem("Key"),
                },
                data: formData
            }).then((res) => {
                let status = res.data.status;
                if (status === 1) {
                    setSuccessMessage(res.data.message)
                    setSmessage(true);
                } 
                else if (status === 0) {
                    setErrorMessage(res.data.message)
                    setEmessage(true)
                }

            }).catch((error) => { console.log(error) })      
    }

    const getUserProfile = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}getUserProfile`,{userId:userlogCheck?.id , loginType:1},{headers:{Authorization:localStorage.getItem("Key")}}).then((res) => {
            let status = res.data.status;

           
            if(status === 1){
                setImageData({
                    ...imageData,
                    name:res.data.userData.name,
                    number:res.data.userData.phone,
                    email:res.data.userData.email,
                    address:res.data.userData.address
                    
                })
            }
            else if(status === 0){
                setErrorMessage(res.data.message)
                setEmessage(true)
            }
        }).catch((error) => {console.log(error)})
    }
    

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: SosJson,
        renderer: 'svg'
    }
    
    const defaultOptions1 = {
        loop: true,
        autoplay: true,
        animationData: AlertJson,
        renderer: 'svg'
    }

    const radarOptions = {
        loop: true,
        autoplay: true,
        animationData: Rader,
        renderer: 'svg'
    }

    const lightOptions = {
        loop: true,
        autoplay: true,
        animationData: noambavailable,
        renderer: 'svg'
    }

    const GetUpdateBookingAmbulance = (id) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}updateBooking`,{userId:userlogCheck?.id,bookingId:id },{headers:{Authorization:localStorage.getItem("Key")}}).then((res) => {
           
        }).catch((error) => {console.log(error)})
    }

    const BookingModalhandleOpen = (id) => {
        setBookingModalOpen(true);
        
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if(oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 2;
                return Math.min(oldProgress + diff, 100);
            });
        }, 600);
            return () => {
              clearInterval(timer);
            }
    }

    const logging = () => {
        if(userlogCheck !== null && userlogCheck?.userType === "customer"){
            setSosLogCheck(true)
            setLogCheck(true);
            setUserName(userlogCheck?.name);
            setUserImage(process.env.REACT_APP_PROFILE_PATH + userlogCheck?.image);
            setImageData({...imageData,preview:process.env.REACT_APP_PROFILE_PATH+userlogCheck?.image})
            if(authKey !== null){
                setSecureKey(authKey);
                
            }
            
        }else if(userlogCheck == null){
            setLogCheck(false);
            setSosLogCheck(false)
        }
    }
    const makeEdit =()=>{
      setMakeEditcls('');
      setMakeAddressCls('');
      setInputdis(false);
      setUpdateButton(true);
      setEditBtn(false);
    }

    const updateProfile = (Form_data) => {
        // console.log(Form_data,'Form_data')
        axios.post(`${process.env.REACT_APP_BASE_URL}updateUserProfile`,{userId:userlogCheck?.id , name:imageData.name , email:imageData.email , address:imageData.address , loginType:1},{headers:{Authorization:localStorage.getItem("Key")}}).then((res) => {
            let status = res.data.status;
            let userDetails = {'userType':'customer','id':res.data.userDetails._id, 'name':res.data.userDetails.name,'phone':res.data.userDetails.phone ,'image':res.data.userDetails.image};
            if(status === 1){
                sessionStorage.setItem("userType", JSON.stringify(userDetails)); 
                setMakeEditcls('textField');
                setMakeAddressCls('addressField')
                setInputdis(true)
                setEditBtn(true)
                setSuccessMessage(res.data.message)
                setSmessage(true);
                setUpdateButton(false);
                setUserName(res.data.userDetails.name)
                
            }else if(status === 0){
                setErrorMessage(res.data.message)
                    setEmessage(true)
            }
        }).catch((error) => {console.log(error)})
       
    }

    const hidePassword=()=>{
        setEyeIcon(false);
        setType('password')  
    }

    const showPassword=()=>{
        setEyeIcon(true);
        setType('name')
    }
    
    const reghidePassword=()=>{
        setRegeyeIcon(false);
        setRegtype('password')
           
    }
    
    const regshowPassword=()=>{
        setRegeyeIcon(true);
        setRegtype('name')
    }

    const newhidePassword=()=>{
        setNeweyeIcon(false);
        setNewtype('password')       
    }

    const newshowPassword=()=>{
        setNeweyeIcon(true);
        setNewtype('name')
    }

    const checkhidePassword=()=>{
        setCheckeyeIcon(false);
        setChecktype('password')      
    }

    const checkshowPassword=()=>{
        setCheckeyeIcon(true);
        setChecktype('name')
    }

    const passHide=()=>{
        setPassEye(false)
        SetPassType('password')
    }

    const passShow=()=>{
        setPassEye(true)
        SetPassType('text')
    }

    const passHide1=()=>{
        setPassEye1(false)
        SetPassType1('password')
    }

    const passShow1=()=>{
        setPassEye1(true)
        SetPassType1('text')
    }
       
    const passHide2=()=>{
        setPassEye2(false)
        SetPassType2('password')
    }

    const passShow2=()=>{
        setPassEye2(true)
        SetPassType2('text')
    }

    //  Login //
      
    const handleOpen = () => {
        setOpen(true);
        setOpenRegister(false);
    }
      
    const handleOpenForgot = () => {
        setOpenForgot(true);
        setOpen(false);
    }

    // otp //
      
    const handleOpenOtp = () =>{
        setOpenOtp(true);
        setOpenForgot(false)
    } 
      
    //Register//
     
    const handleOpenRegister = () =>{
        setOpenRegister(true);
        setOpen(false)
    } 
      
    //RESET//
      
    const handleOpenReset = () => {
        setOpenReset(true);
        setOpenOtp(false);
    }

    //   Register Otp ////
    
    const handleOpenRegisterOtp = () => {
        setOpenRegisterOtp(true);
        setOpenRegister(false);
    }
    
    // End Register Otp //

    const [openMap, setOpenMap] = useState(false);
    const handleOpenMap = () => {
      setOpenMap(true);
      handleClose()
  }
  const handleCloseMap = () => {
    setOpenMap(false);
    setAddAddress({...addAddress , address:address})
  
  };
    const onSubmit = async (data)=>{
        setUserPhone(data.phone);
        axios.post(`${process.env.REACT_APP_BASE_URL}registration`,{name:data.name , email:data.email , phone:data.phone ,password:data.password , address:data.address , deviceid:"",platform:3},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            let status = res.data.status;
            if(status === 1){
                setRegverify(true);
                handleOpenRegisterOtp();
                setSuccessMessage(res.data.message)
                setSmessage(true);
            }else if(status === 0){
                setErrorMessage(res.data.message)
                setEmessage(true)
                setPhoneExist(true);
            }
        }).catch((error) => {console.log(error)})
    }
    
    const forgotSubmit = async ()=>{
        setLoaderOpen(true)
        if( forgotPhone === "" ){
            setForgotphoneError(true)
            setPhoneError(false)
            setLoaderOpen(false)
        }else if(forgotPhone.match(phone)){
            setLoaderOpen(false)
            setUserPhone(forgotPhone);
            setPhoneError(false)
            axios.post(`${process.env.REACT_APP_BASE_URL}userForgotPassword`,{phone:forgotPhone},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            let status = res.data.status;
            if(status === 1){
                setForgotPhnSuccess(true);
                setOpenForgot(false);
                handleOpenOtp();
                setSuccessMessage(res.data.message)
                setSmessage(true);
            }else if(status === 0){
                setErrorMessage(res.data.message)
                setEmessage(true)
                setForgotPhnerror(true)
            }
            }).catch((error) => {console.log(error)})
        }else{
            setForgotphoneError(false)
            setPhoneError(true)
        }
    }

    const verifyForgotOtp = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}verifyOtp`,{phone:userPhone , otp:OTP},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            let status = res.data.status;
            if(status === 1){
                setotpVerify(true);
                handleCloseRegisterOtp();
                handleOpenReset();
                setSuccessMessage(res.data.message)
                setSmessage(true);
                // handleOpenRegisterOtp();
            }else if(status === 0){
                setErrorMessage(res.data.message)
                setEmessage(true)
                setForgotOtpError(true);
            }

        }).catch((error) => {console.log(error)})
    }

    const verifyRegistrationOtp = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}verifyOtp`,{phone:userPhone , otp:registrationOTP},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            let status = res.data.status;
            if(status === 1){
                
                setotpVerify(true);
                handleCloseRegisterOtp();
                setSuccessMessage(res.data.message)
                setSmessage(true);
                // handleOpenRegisterOtp();
            }else if(status === 0){
                setErrorMessage(res.data.message)
                setEmessage(true)
                setRegOtpError(true);
            }
        }).catch((error) => {console.log(error)})
      }

    const resetPassword=()=>{
        if(!(newPassword.match(password))){
            setNpassword(true);
        }else if(!(checkPassword.match(password))){
            setNpassword(false);
            setCpassword(true);
        }else if(newPassword !== checkPassword){
            setCpassword(false);
            setCompPassword(true);
        }else{
            setCompPassword(false);
            axios.post(`${process.env.REACT_APP_BASE_URL}userResetPassword`,{phone:userPhone , password:checkPassword},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
                let status = res.data.status;
                if(status === 1){
                    setOpenReset(false);
                    setResetSuccess(true);
                    setSuccessMessage(res.data.message)
                    setSmessage(true);
                }else if(status === 0){
                    setErrorMessage(res.data.message)
                    setEmessage(true)
                    setResetError(true)
                }
    
            }).catch((error) => {console.log(error)})
        }
    }

    const resendOtp=()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}resendOtp`,{phone:userPhone},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
            let status = res.data.status;
            if(status === 1){
                setResendSuccess(true)
                setSuccessMessage(res.data.message)
                setSmessage(true);
            }else if(status === 0){
                setErrorMessage(res.data.message)
                setEmessage(true)
            }
        }).catch((error) => {console.log(error)})
    }

    const trackStatus = () =>{
        setInterval(()=>{
                if(userlogCheck?.id){
                    axios.get(`${process.env.REACT_APP_BASE_URL}getUserBookingStatus/${userlogCheck?.id}`,{headers:{Authorization:localStorage.getItem("Key")}}).then((res) => {
                        if(res && res.data.status === 1){
                           if(res.data.bookingStatus === 0){
                                setSosLogCheck(true);
                           }else if(res.data.bookingStatus === 1){
                                setSosLogCheck(false);
                           }
                        }
                    
                    }).catch((error) => {
                        if(error.response.status === 401){
                            setErrorMessage("Your acoount was logged in from another device")
                            setEmessage(true)
                            setTimeout(() => {
                                userLogOut();
                            }, 2000);
                        }
                    })
                }
            },10000)
        }
    
    const userLogin = (e) =>{
        
        if(logPhone === ""){
            setLogPassCheckEmpty(true);
            e.preventDefault();
        }else if(!(logPhone.match(phone))){
            setLogPassCheckEmpty(false);
            setLogPhoneCheck(true);
            e.preventDefault();
        }else if(logPassword === ""){
            setLogPassCheckEmpty(false);
            setLogPhoneCheck(false);
            setLogCheckEmpty(true);
            e.preventDefault();
        }else if(!(logPassword.match(password))){
            setLogPassCheckEmpty(false);
            setLogPhoneCheck(false);
            setLogCheckEmpty(false);
            setLogPassCheck(true);
            e.preventDefault();
        }else{
            setLogPassCheckEmpty(false);
            setLogPhoneCheck(false);
            setLogPassCheck(false);
            e.preventDefault();
            navigator.geolocation.getCurrentPosition(function(position){
                axios.post(`${process.env.REACT_APP_BASE_URL}userLogin`,{phone:logPhone , password:logPassword , deviceid:"" , latitude:position.coords.latitude , longitude:position.coords.longitude,platform:3},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then((res) => {
                    let status = res.data.status;
                    if(status === 1){
                        let userDetails = {'userType':'customer','id':res.data.details._id, 'name':res.data.details.name,'phone':res.data.details.phone ,'image':res.data.details.image};
                        sessionStorage.setItem("userType", JSON.stringify(userDetails)); 
                        setSuccessMessage(res.data.message)
                        setSmessage(true);
                        logging();
                        setHomeclass(true);
                        localStorage.setItem("latitude", position.coords.latitude);
                        localStorage.setItem("longitude", position.coords.longitude);
                        localStorage.setItem("Key", res.data.details.authKey);
                        // console.log(res.data.details , "checking");
                        navigate('/homepage');
                        window.location.reload();
                         getAddress();
                        setSecureKey(res.data.details.authKey);
                        
                    }else if(status === 0){
                        setErrorMessage(res.data.message)
                        setEmessage(true);
                        setInvalid(true);
                        setErrorMsg(res.data.message)
                    }
                    e.preventDefault();
                }).catch((error) => {if(error.response.status === 401){
                    setErrorMessage("Your acoount was logged in from another device")
                    setEmessage(true)
                    setTimeout(() => {
                        userLogOut();
                    }, 2000);
                    
                }})
            });
        }
    }

    const getAddress = () => {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.setLanguage("en");
        Geocode.fromLatLng(localStorage.getItem("latitude"), localStorage.getItem("longitude")).then(
            (response) => {
              const address = response.results[0].formatted_address;
              setAddress(address);
              localStorage.setItem("address", address);
            },
            (error) => {
                if(error.response && error.response.status === 401){
                    setErrorMessage("Your acoount was logged in from another device")
                    setEmessage(true)
                    setTimeout(() => {
                        userLogOut();
                    }, 2000);}
            }
          );
    }

    const sosBooking = (id) => {
        // setSorryScreen(true)
        BookingModalhandleOpen(id);
        let check = setInterval(() => {
            axios.post(`${process.env.REACT_APP_BASE_URL}getBookingStatus`,{bookingId:id ,userId:userlogCheck?.id , loginType:1},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    if(responce.data.status === 1){
                        clearInterval(check);
                        BookingModalhandleClose();
                        clearTimeout(checkaftmin);
                        window.open(`#/Sos/id=${id}` , '_blank')
                    }else if(responce.data.status === 0){
                    }
                }
            ).catch((error) => {if(error.response.status === 401){
                setErrorMessage("Your acoount was logged in from another device")
                setEmessage(true)
                setTimeout(() => {
                    userLogOut();
                }, 2000);}})
        } , 5000)

        const checkaftmin = setTimeout(()=>{
            axios.post(`${process.env.REACT_APP_BASE_URL}getBookingStatus`,{bookingId:id , userId:userlogCheck?.id , loginType:1},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    if(responce.data.status === 1){
                        clearInterval(check);
                        BookingModalhandleClose();
                        window.open(`#/Sos/id=${id}` , '_blank')
                    }else{
                        setSorryScreen(false)
                        GetUpdateBookingAmbulance(id)
                        setTimeout(()=>{
                            BookingModalhandleClose();
                        } , 5000)
                    }
                }
            ).catch((error) => {if(error.response.status === 401){
                setErrorMessage("Your acoount was logged in from another device")
                setEmessage(true)
                setTimeout(() => {
                    userLogOut();
                }, 2000);}})
        }, 60000);
    }

    const sosSubmit = () => {
        let getAddressdetails = localStorage.getItem("address");
        navigator.geolocation.getCurrentPosition(function(position){
            axios.post(`${process.env.REACT_APP_BASE_URL}booking`,{loginType:1 ,userId:userlogCheck?.id , fromLat:position.coords.latitude, fromLong:position.coords.longitude , bookingType:2 , fromLocation:getAddressdetails , loginType:1,fromLocType:1},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                (responce) => {
                    if(responce.data.status === 1){
                        setSorryScreen(true);
                        setSuccessMessage(responce.data.message)
                        setSmessage(true);
                        sosBooking(responce.data.bookingId)
                        axios.post(`${process.env.REACT_APP_BASE_URL}changePushStatus`,{userId:userlogCheck?.id ,bookingId:responce.data.bookingId},{headers:{Authorization:localStorage.getItem("Key")}}).then(
                            (responce) => {
                                
                            }
                        ).catch((error) => {if(error.response.status === 401){
                            setErrorMessage("Your acoount was logged in from another device")
                            setEmessage(true)
                            setTimeout(() => {
                                userLogOut();
                            }, 2000);}})
                    }else{
                        BookingModalhandleOpen();
                        setSorryScreen(false)
                        GetUpdateBookingAmbulance(responce.data.bookingId)
                        setTimeout(()=>{
                            BookingModalhandleClose();
                        } , 10000)
                    }
                }
            ).catch((error) => {if(error.response.status === 401){
                setErrorMessage("Your acoount was logged in from another device")
                setEmessage(true)
                setTimeout(() => {
                    userLogOut();
                }, 2000);}})

        })
    }

    const userLogOut = () =>{
        sessionStorage.removeItem("userType");
        localStorage.removeItem("latitude");
        localStorage.removeItem("longitude");
        setHomeclass(false)
        setLogOutopen(false);
        logging();
        setLogCheck(false);
        setSosLogCheck(false)
        navigate('homepage');
        window.location.reload();
    }
    const setMap = () => {
        navigator.geolocation.getCurrentPosition(function(position) {
        setCenter({
            lat:position.coords.latitude,
            lng:position.coords.longitude
        })
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.setLanguage("en");
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (response) => {
            const address = response.results[0].formatted_address;
            setAddress(address);
        },
        (error) => {
            console.error(error);
        }
        );
        });
    }

    const autocompleteRef = useRef(null);
    const onLoad = (autocomplete) => {
        // console.log('Autocomplete: ', autocomplete);
       autocompleteRef.current = autocomplete;
     };
    const originRef = useRef()
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey : process.env.REACT_APP_GOOGLE_KEY,
        libraries: ['places'],
        })
        
    const MapStyle = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: '2% 2% 2% 2%',
        outline:0,
        borderRadius:'10px'
      };
      const setMarkerfromsearch = (lat ,lng) => {
        setCenter({
          lat:lat,
          lng:lng
        })
        setLatlong({
          latitude:lat,
          longitude:lng
        })
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.setLanguage("en");
        Geocode.setRegion("IN");

        Geocode.fromLatLng(lat, lng).then(
            (response) => {
              const address = response.results[0].formatted_address;
              let city = address.split(',')
              setCityName(city[2]);
              setAddress(address);
            },
            (error) => {
              console.error(error);
            }
          );
    }
      const googleMap = <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '95%' }}
            options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            }}
            onClick={ev => {
                setCenter({
                    lat:ev.latLng.lat(),
                    lng:ev.latLng.lng()
                })
                setMarker(ev)
              }}
        >
            <MarkerF position={center}></MarkerF>
        </GoogleMap>
        const setMarker = (ev) => {
            setCenter({
              lat:ev.latLng.lat(),
              lng:ev.latLng.lng()
            })
            setLatlong({
              latitude:ev.latLng.lat(),
              longitude:ev.latLng.lng()
            })
            Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
            Geocode.setLanguage("en");
            Geocode.fromLatLng(ev.latLng.lat(), ev.latLng.lng()).then(
                (response) => {
                  const address = response.results[0].formatted_address;
                  let city = address.split(',')
                  setCityName(city[2]);
                  setAddress(address);
                },
                (error) => {
                  console.error(error);
                }
              );
        }
        const savaddresses = savAdd.map((x) => <>
            <Grid item container justifyContent={'center'} alignItems={'center'} sx={{mt:2}} className={'addressHorizontal'}>
                <Grid item md={10} sx={{paddingLeft:'10px'}} >
                    <Typography color={'#129547'}  variant='h6'>{x.title}</Typography>
                    <Typography>{x.address}</Typography>
                </Grid>
                <Grid item md={2}>
                    <Grid container justifyContent={'center'}>
                        <DeleteOutlineOutlinedIcon className="actioinIcon" sx={{color:'#E11F26',cursor:'pointer', textAlign:"center"}} onClick={()=>deleteAddress(x._id)}/>
                    </Grid>
                </Grid>
            </Grid>
        </>)

function onPlaceChanged(place){
   // console.log(place,'EEEEEEEEE')
   setMarkerfromsearch(place.geometry.location.lat() , place.geometry.location.lng())
}


    return(
        <>
        <Grid className = 'header_box'>
            <Container className = 'widthSet webHeader'>
                <Grid sx={{mt:1}} container direction = "row" justifyContent = "space-between" alignItems = "center">
                    <Grid item className = "imgRespo">
                        <img src = {Logo} alt="Logo"/>
                    </Grid>
                    <Grid item sx={{display:'flex'}} alignItems="center" className="imgRespo">
                        {soslogCheck?<Grid sx={{cursor:'pointer'}} onClick={() => {sosSubmit()}}><Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}/></Grid>:<img src={NormalSos} alt="sos"/>}
                        <Grid sx={{display:'flex'}} >
                        {location.pathname === "/" ? <NavLink to="homepage" className="navHome">
                                <ListItem component="div">
                                    <ListItemText className='staticLink' primary="Home"/>
                                </ListItem>
                        </NavLink>
                            :<NavLink to="homepage" className="navHome">
                                <ListItem component="div">
                                    <ListItemText className='aboutLink' primary="Home"/>
                                </ListItem>
                            </NavLink>}
                            
                            <NavLink to="AboutUs" className="navHome">
                                <ListItem component="div">
                                    <ListItemText className='aboutLink' primary="About Us"/>
                                </ListItem>
                            </NavLink>
                        </Grid>
                    </Grid>
                     {logCheck?<Grid item >
                        <Grid container justifyContent='center' onClick={handleClickProfile} alignItems="center">
                            <IconButton
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={openProfile ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openProfile ? 'true' : undefined}>
                                {userlogCheck?.image ? <img src={userImage} height={36} width={36} style={{borderRadius:'100px'}} alt={"upload image"} />:<Avatar alt="Remy Sharp" src={AvatarImg} /> }
                            </IconButton>
                            <Typography className="userNameWidth" sx={{ mx: 2,cursor:'pointer' }} title={userName}>{userName}</Typography>
                        </Grid> 
                        </Grid>:<Grid item component="div" className="login_text imgRespo" onClick={handleOpen}> Login/Registration  </Grid>}
                        <Menu
                        anchorEl={anchorElProfile}
                        className='profileModal'
                        id="account-menu"
                        open={openProfile}
                        onClose={handleCloseProfile}
                        onClick={handleCloseProfile}
                        PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        
                            },
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                        }}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem onClick={handleOpenUserProfile} sx={{p:2}}>
                                <ListItemIcon>
                                    <img src={ProfileIcon} alt="icon"/>
                                </ListItemIcon>
                                <Typography>Profile</Typography> 
                            </MenuItem>
                            <MenuItem onClick={() => {setOpenUserChangePassword(true)}}  sx={{p:2}}>
                                <ListItemIcon >
                                    <img src={ChangeIcon} alt="icon"/>
                                </ListItemIcon>
                                <Typography>Change Password</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {setOpenUserChangeNumber(true)}} sx={{p:2}}>
                                <ListItemIcon>
                                    <img src={PhoneIcon} alt="icon"/>
                                </ListItemIcon>
                                <Typography>Change Phone Number</Typography> 
                            </MenuItem>
                            <MenuItem onClick={() => {setSavedAddress(true)}} sx={{p:2}}>
                                <ListItemIcon>
                                    <img src={AddressImg} alt="icon"/>
                                </ListItemIcon>
                                <Typography>Saved address</Typography> 
                            </MenuItem>
                            <MenuItem sx={{p:2}} onClick={() => {navigate('/history');}}>
                                <ListItemIcon>
                                    <img src={HistoryIcon} alt="icon"/>
                                </ListItemIcon>
                                <Typography>History</Typography> 
                            </MenuItem>
                            <MenuItem onClick={() => {setLogOutopen(true)}} sx={{p:2}}>
                                <ListItemIcon>
                                    <img src={LogoutIcon} alt="icon"/>
                                </ListItemIcon>
                                <Typography>Logout</Typography>
                            </MenuItem>
                        </Menu>
                </Grid>
            </Container>
        </Grid>
        <Container className='widthSet header_box responsiveheader'>
            <Grid sx={{mt:1}} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item className="imgRespo">
                    <img src={Logo} alt="Logo"/>
                </Grid>
                {logCheck?<Grid item >
                    <Grid container justifyContent='center' onClick={handleClickProfile} alignItems="center">
                        <IconButton
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={openProfile ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openProfile ? 'true' : undefined}>
                            {userlogCheck?.image ? <img src={userImage} height={36} width={36} style={{borderRadius:'100px'}} alt={"upload image"}/>:<Avatar alt="Remy Sharp" src={AvatarImg} /> }
                        </IconButton>
                        <Typography className="userNameWidth" sx={{ mx: 2 }}>{userName}</Typography>
                    </Grid> 
                    </Grid>:<Grid item component="div" className="login_text imgRespo" onClick={handleOpen}> Login/Registration  </Grid>}
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <NavLink to="homepage" className="navHome">
                        <ListItem component="div" >
                            <ListItemText className='aboutLink' primary="Home"/>
                        </ListItem>
                    </NavLink>
                </Grid>
                <Grid item sx={{display:'flex'}} alignItems="center" className="imgRespo">
                {soslogCheck?<Grid sx={{cursor:'pointer'}} onClick={() => {sosSubmit()}}><Lottie
                options={defaultOptions}
                height={100}
                width={100}/></Grid>:<img src={NormalSos} alt="sos"/>}
                </Grid>
                <Grid item>
                    <NavLink to="AboutUs" className="navHome">
                        <ListItem component="div">
                            <ListItemText className='aboutLink' primary="About Us"/>
                        </ListItem>
                    </NavLink>
                </Grid>
            </Grid>
        </Container>

{/* START LOGIN MODAL */}
    <Modal
        keepMounted
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
            <Box className="modalwidth" sx={style}>
                <Grid container justifyContent='center'>
                    <Grid item xs={12} sm={12} md={10} sx={{mt:2}}>
                    <CloseIcon onClick={()=>{handleClose()}} className={"closeIconPosition"}/>
                        <Grid item>
                            <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                                Login
                            </Typography>
                        </Grid>
                        <form>
                        <Grid item>
                            <FormLabel className={'formLabel'}>Phone Number</FormLabel>
                            <TextField fullWidth className={'text_field'} onChange={e=>{setLogPhone(e.target.value)}} />
                            {logPassCheckEmpty && <span className='error'>please enter Phone number</span>}
                            {logPhoneCheck && <span className='error'>please enter valid number</span>}
                        </Grid>
                        <Grid item className={"parent_position"} >
                            <FormLabel className={'formLabel'}>Password</FormLabel>
                            <TextField fullWidth type={type} className={'text_field'} onChange={e=>{setLogPassword(e.target.value)}}/>
                                {logPassCheck && <span className='error'>please enter valid password</span>}
                                {logCheckEmpty && <span className='error'>please enter password</span>}
                                {eyeIcon?
                                <RemoveRedEyeOutlinedIcon onClick={hidePassword} className={'eyeicon_position'} />:
                                <VisibilityOffOutlinedIcon onClick={showPassword} className={'eyeicon_position'} />}
                            <Typography sx={{cursor:'pointer'}} onClick={handleOpenForgot} gutterBottom component="div" color="#E11F26" >
                                Forgot Password?
                            </Typography>
                        </Grid>
                            <Grid container justifyContent='center'>
                            <Grid item  sx={{my:3}}>
                                <Button className="customerLogin"  onClick={userLogin}>Login</Button>
                            </Grid>
                        </Grid>
                        </form>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <Typography>Don’t have an account? <span href="#" className='registerText' onClick={handleOpenRegister}>Register Now</span></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
    </Modal>
{/* END LOGIN MODAL */}
{/* START REGISTRATION MODAL */}
    <Modal
        open={openRegister}
        // onClose={handleCloseRegister}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <Box className="modalwidth " sx={style}>
                <Grid className="registerModal">
                <CloseIcon onClick={()=>{handleCloseRegister()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                        Registration
                    </Typography>
                </Grid>
                    <Grid className="formResponsive">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={6}>
                        <Grid  justifyContent='center'>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel className={'formLabel'}>Full Name</FormLabel>
                                <TextField fullWidth name="name" className={'text_field'} {...register('name',{required: true})}></TextField>
                                {errors.name && errors.name.type === "required" && <p className='error mt-0'>Please enter Full-name</p>}
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid justifyContent='center' >
                            <Grid item sx={{mt:2}}  >
                                <FormLabel className={'formLabel'}>Phone Number</FormLabel>
                                <TextField fullWidth name="phone" className={'text_field'} {...register('phone',{required: true,pattern:/^\d{10}$/})}></TextField>
                                {errors.phone && errors.phone.type === "required" && <p className='error mt-0'>Please enter Phone number</p>}
                                {errors.phone && errors.phone.type === "pattern"  && <p className='error mt-0'>please enter valid Number</p>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={6}>
                        <Grid justifyContent='center'>
                            <Grid item sx={{mt:2}}  >
                                <FormLabel className={'formLabel'}>Email</FormLabel>
                                <TextField fullWidth name="email" className={'text_field'}  {...register('email',{required: true,pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}></TextField>
                                {errors.email && errors.email.type === "required" && <p className='error mt-0'>Please enter Email</p>}
                                {errors.email && errors.email.type === "pattern"  && <p className='error mt-0'>please enter valid Email</p>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid justifyContent='center'>
                            <Grid item  sx={{mt:2}}  className={"parent_position"}>
                                <FormLabel className={'formLabel'}>Password</FormLabel>
                                <TextField type={regtype} name="password" className={'text_field'} {...register('password',{required: true,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/})}></TextField>
                                {errors.password && errors.password.type === "required" && <p className='error mt-0'>Please enter Password</p>}
                                {errors.password && errors.password.type === "pattern"  && <p className='error mt-0'>please enter valid Password</p>}
                                {regeyeIcon ? <RemoveRedEyeOutlinedIcon onClick={reghidePassword} className={'eyeicon_position'} /> : <VisibilityOffOutlinedIcon onClick={regshowPassword} className={'eyeicon_position'} />}
                                <Typography sx={{width: '255px', color:'#979899', fontSize:12}}>* Must contain one lowercase, uppercase, numeric and  Special Characters with atleast 8 characters
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <FormLabel>Address</FormLabel>
                    <TextField className={'address_field formLabel'} multiline variant='outlined' rows={4} fullWidth {...register('address',{required: true})}/>
                    {errors.address && errors.address.type === "required" && <p className='error mt-0'>Address cannot be Empty</p>}
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item sx={{my:3}} >
                        {/* <Button  className="customerLogin"bgColor='#129547' onClick={handleOpenRegisterOtp} >Submit</Button> */}
                        <Button  className="customerLogin"  onClick={handleSubmit(onSubmit)}>Submit</Button>
                    </Grid> 
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item>
                        <Typography>Already a member? Please <span href="#" className='signText' onClick={handleOpen}> Sign in</span> </Typography>
                    </Grid> 
                </Grid>
                </Grid>
                </Grid>
            </Box>
    </Modal>
{/* END REGISTRATION MODAL */}
{/*STRAT FORGOTPASSWORD MODAL */}
    <Modal
        open={openForgot}
        // onClose={handleCloseForgot}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <Box className="modalwidth" sx={style}>
            <CloseIcon onClick={()=>{handleCloseForgot()}} className={"closeIconPosition"}/>
                <Grid container justifyContent='center' sx={{mt:3}}>
                    <Grid item>
                        <Typography className="titleName" align="center" variant="h4" gutterBottom component="div">
                            Forgot password
                        </Typography>
                        <Typography className="forgot_text" align="center" variant="h6" gutterBottom component="div">
                            An OTP will be sent to registered phone number
                        </Typography>
                    </Grid>
                    <Grid item md={10} sx={{mt:2}}  >
                        <FormLabel className={'formLabel'}>Phone number</FormLabel>
                        <TextField name="forgotphone" className={'text_field'} onChange={e =>{setForgorPhone(e.target.value)}}></TextField>
                        {phoneError ? <span className='error'>please give valid number</span>:<span></span>}
                            {forgotphoneError ? <span className='error'>please enter phone number</span>:<span></span>}
                    </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item sx={{my:3}} >
                        <Button  className="customerLogin" onClick={forgotSubmit}>Send</Button>
                    </Grid> 
                    <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loaderOpen}
                    onClick={handleClose}
                    invisible={true}
                >
                    <Lottie 
                    options={defaultOptions1}
                    height={100}
                    width={100}
                    />
                </Backdrop>
                </Grid>
            </Box>
    </Modal>

{/*END FORGOTPASSWORD MODAL */}

{/* START OTP MODAL */}
    <Modal
        open={openOtp}
        // onClose={handleCloseOtp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <Box className="modalwidth" sx={style}>
            <CloseIcon onClick={()=>{handleCloseOtp()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName" align="center" variant="h4" gutterBottom component="div">
                        Verification Code
                    </Typography>
                    <Typography className="forgot_text" align="center" variant="h6" gutterBottom component="div">
                        Please enter the verification code sent to your mobile number
                    </Typography>
                </Grid>
                <Grid container  justifyContent='center' sx={{my:3}}>
                    <OTPInput
                        value={OTP}
                        onChange={setOTP}
                        className={"otpText"}
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        secure />
                    {/* <ResendOTP handelResendClick={() => console.log("Resend clicked")} /> */}
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item sx={{my:3}} >
                        <Button className="customerLogin"  onClick={verifyForgotOtp}>Verify</Button>
                    </Grid> 
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item>
                        <Typography>Didn't receive verification code? <span className='registerText' onClick={resendOtp}> Resend again</span> </Typography>
                    </Grid> 
                </Grid>            
                        
            </Box>
    </Modal>

{/* END OTP MODAL */}

{/*START RESET PASSWORD */}
    <Modal
        open={openReset}
        // onClose={handleCloseReset}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
            <Box className="modalwidth" sx={style}>
            <CloseIcon onClick={()=>{handleCloseReset()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                        Reset Password
                    </Typography>
                </Grid>
                <Grid container  justifyContent='center' lg={12}>
                    <Grid container item  justifyContent='center' lg={10}>
                        <Grid container item justifyContent='center' >
                            <Grid item  sx={{mt:2}}  className={"parent_position"} >
                                <FormLabel className={'formLabel'} >New Password &nbsp;&nbsp;&nbsp;&nbsp;</FormLabel>
                                <TextField type={newtype} className={'text_field'} onChange={e => {setNewPassword(e.target.value)}}></TextField>
                                {npassword?<span className='error'>please give valid password</span>:<span></span>}
                                    {neweyeIcon ? <RemoveRedEyeOutlinedIcon onClick={newhidePassword} className={'eyeicon_position'} /> : <VisibilityOffOutlinedIcon onClick={newshowPassword} className={'eyeicon_position'} />}
                                <Typography sx={{width: '345px', color:'#979899', fontSize:12}}>* Must contain one lowercase, uppercase, numeric and 
                                    Special Characters with atleast 8 characters
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center' >
                            <Grid item  sx={{mt:2}}  className={"parent_position"} >
                                <FormLabel className={'formLabel'}>Confirm Password</FormLabel>
                                <TextField type={checktype} className={'text_field'} onChange={e => {setCheckPassword(e.target.value)}}></TextField>
                                {cpassword?<span className='error'>please give valid password</span>:<span></span>}
                                {compPassword?<span className='error'>Password Doesn't Match</span>:<span></span>}
                                {checkeyeIcon ? <RemoveRedEyeOutlinedIcon onClick={checkhidePassword} className={'eyeicon_position'} /> : <VisibilityOffOutlinedIcon onClick={checkshowPassword} className={'eyeicon_position'} />}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:3}} >
                                <Button  className="customerLogin" onClick={resetPassword} >Submit</Button>
                            </Grid> 
                        </Grid>
                        </Grid>
                </Grid>
            </Box>
    </Modal>
{/* END RESET PASSWORD */}

{/* START REGISTRATON OTP */}
    <Modal
        open={openRegisterOtp}
        // onClose={handleCloseRegisterOtp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <Box className="modalwidth" sx={style}>
            <CloseIcon onClick={()=>{handleCloseRegisterOtp()}} className={"closeIconPosition"}/>
                <Grid>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                        Verification Code
                    </Typography>
                    <Typography className="forgot_text" align="center" variant="h6" gutterBottom component="div">
                        Please enter the verification code sent to your mobile number
                    </Typography>
                </Grid>
                    <Grid container  justifyContent='center' sx={{my:3}}>
                    <OTPInput
                        value={registrationOTP}
                        onChange={setRegistrationOTP}
                        className={"otpText"}
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        secure
                    />
                    {/* <ResendOTP handelResendClick={() => console.log("Resend clicked")} /> */}
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item sx={{my:3}} >
                        <Button className="customerLogin"  onClick={verifyRegistrationOtp}>Verify</Button>
                    </Grid> 
                </Grid>
                <Grid container justifyContent='center'>
                    <Grid item>
                        <Typography>Didn’t receive verification code? <span className='registerText' onClick={resendOtp}> Resend again</span> </Typography>
                    </Grid> 
                </Grid>            
            </Box>
    </Modal>
{/* END REGISTRATION OTP */}
    <Modal
        open={logOutopen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modalwidth" sx={LogOutstyle}>
            <Typography  id="modal-modal-title" variant="h6" component="h2">
            Do you really want to Logout?
            </Typography>
            <Grid container   justifyContent="space-around" sx={{ mt: 5 }}>
                <Button className="yesButton" onClick={userLogOut}>Yes</Button>
                <Button className="noButton" onClick={LogOuthandleClose}>No</Button>
            </Grid>
        </Box>
    </Modal>

{/* Start Profile Modal */}
    <Modal
        open={openUserProfile}
        // onClose={handleCloseUserProfile}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <Box className="modalwidth profileheight " sx={style}>
                <Grid>
                    <CloseIcon  onClick={()=>{handleCloseUserProfile()}} className={"closeIconPosition"}/>
                </Grid>
                <Grid>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                        Profile
                    </Typography>
                </Grid>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={setPicture}
                    />
                <Box sx={{ width: '100%' }} >
                <Grid>
                    <Grid container alignItems='baseline' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid container item md={12} justifyContent="end">
                         { editBtn && <img style={{zIndex:9999,cursor:'pointer'}} src={UploadProfileIcon} alt="img" onClick={makeEdit} />}
                        </Grid>
                        <Grid className="mt-4" container item md={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
                            <Grid item md={6}>
                                <Grid container justifyContent="space-between"                                  alignItems="center">
                                    <Grid item md={8}>
                                        <img src={imageData.preview } height={150} width={150} style={{borderRadius:'100px'}} alt={"upload image"}/>
                                    </Grid>
                                    <Grid item md={4}>
                                        <label htmlFor="raised-button-file">
                                            <Typography className="uploadbtn">Upload</Typography>
                                        </label>
                                    </Grid>
                                </Grid>
                                    <Grid item sx={{mt:2}}>
                                        <FormLabel>User name</FormLabel>
                                        <TextField fullWidth name="name" value={imageData.name} className={`text_field ${makeEditCls}`} disabled={inputdis} {...register4('name',{required: !Boolean(imageData.name)})} onChange={(e)=>{
                                            setImageData({...imageData, name:e.target.value})
                                        }} />
                                        { imageData.name === " " && errors4.name && errors4.name.type === "required" && <p className='error mt-0'>Please enter name</p>}
                                    </Grid>
                                    <Grid item sx={{mt:2}}  >
                                        <FormLabel>Phone number</FormLabel>
                                        <TextField fullWidth name="number" value={imageData.number} className={`text_field ${makeEditCls}`} disabled={inputdis} {...register4('phone',{required: !Boolean(imageData.number),pattern:/^\d{10}$/})} onChange={(e)=>{
                                            setImageData({...imageData, number:e.target.value})}}/>
                                            { imageData.number === "" && errors4.phone && errors4.phone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                                            {errors4.phone && errors4.phone.type === "pattern"  && <p className='error mt-0'>Please enter valid phone number</p>}
                                    </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <Grid item >
                                    <FormLabel>Email</FormLabel>
                                    <TextField fullWidth name="email" value={imageData.email} className={`text_field ${makeEditCls}`} disabled={inputdis} {...register4('email',{required: !Boolean(imageData.email),pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} onChange={(e)=>{setImageData({...imageData, email:e.target.value})}}/>
                                    { imageData.email=== "" && errors4.email && errors4.email.type === "required" && <p className='error mt-0'>Please enter email</p>}
                                    {errors4.email && errors4.email.type === "pattern"  && <p className='error mt-0'>Please enter valid email</p>}
                                </Grid>
                                <Grid item sx={{mt:2}}>
                                    <FormLabel>Address</FormLabel>
                                    <TextField value={imageData.address}  className={`address_field ${makeAddressCls}`}  multiline variant='outlined' rows={3} fullWidth disabled={inputdis} {...register4('address',{required: !Boolean(imageData.address)})} onChange={(e)=>{setImageData({...imageData, address:e.target.value})}}/>
                                    {imageData.address === "" &&   errors4.address && errors4.address.type === "required" && <p className='error mt-0'>Please enter address</p>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid item sx={{my:3}} >
                            {updateButton?<Button  className="customerLogin"  onClick={handleSubmit4(updateProfile)}>update</Button>:<Button  className="customerLogin" onClick={()=>{ setOpenUserProfile(false) }} >Ok</Button>}
                            
                        </Grid> 
                    </Grid>
                    </Grid>
                </Box>
            
            </Box>
            
    </Modal>
{/* End Profile Modal */}

{/* Start Change Password */}
    <Modal
        open={openUserChangePassword}
        // onClose={handleCloseUserChangePassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
           <Box className="modalwidth" sx={style}>
                <Grid container justifyContent="center" alignItems='baseline' rowSpacing={1} columnSpacing = {{ xs: 1, sm: 2, md: 3 }}>
                    <CloseIcon onClick={()=>{handleCloseUserChangePassword()}} className={"closeIconPosition"}/>
                        <Grid item md={10}>
                            <Grid item>
                                <Typography className="titleName" align="center" variant="h4" gutterBottom component="div">
                                  Change password
                                </Typography>
                            </Grid>
                            <Grid item sx={{mt:2}} className={"parent_position"}  >
                                <FormLabel>Old password</FormLabel>
                                <TextField fullWidth name="name" value={changePassword.oldPassword}  type={passtype}  className={'text_field'}
                                {...register2('oldPassword',{required: true,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+-_()=]).*$/})}
                                onChange={(e)=>{
                                    setChangePassword({
                                        ...changePassword,
                                        oldPassword:e.target.value
                                    })
                                }}/>
                              {passEye ? <RemoveRedEyeOutlinedIcon onClick={passHide} className={'eyeicon_position'} /> :<VisibilityOffOutlinedIcon  onClick={passShow} className={'eyeicon_position'} />}
                              {errors2.oldPassword && errors2.oldPassword.type === "required" && <p className='error mt-0'>Please enter Old Password</p>}
                              {errors2.oldPassword && errors2.oldPassword.type === "pattern"  && <p className='error mt-0'>Please enter valid Old Password</p>}
                            </Grid>
                            <Grid item sx={{mt:2}} className={"parent_position"}  >
                                <FormLabel>New password</FormLabel>
                                <TextField fullWidth name="name" type={passtype1} value={changePassword.newPassword} className={'text_field'}
                                {...register2('newPassword',{required: true,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+-_()=]).*$/})}
                                onChange={(e)=>{
                                    setChangePassword({
                                        ...changePassword,
                                        newPassword:e.target.value
                                    })
                                }} />
                              {passEye1 ? <RemoveRedEyeOutlinedIcon onClick={passHide1} className={'eyeicon_position'} /> :<VisibilityOffOutlinedIcon  onClick={passShow1} className={'eyeicon_position'}/>}
                              {errors2.newPassword && errors2.newPassword.type === "required" && <p className='error mt-0'>Please enter New Password</p>}
                              {errors2.newPassword && errors2.newPassword.type === "pattern"  && <p className='error mt-0'>Please enter valid New Password</p>}
                            </Grid>
                            <Grid item sx={{mt:2}} className={"parent_position"}  >
                                <FormLabel>Confirm password</FormLabel>
                                <TextField fullWidth name="name" type={passtype2} value={changePassword.confirmPassword}  className={'text_field'}
                                {...register2('conformPassword',{required: true,pattern:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+-_()=]).*$/})}
                                onChange={(e)=>{
                                    setChangePassword({
                                        ...changePassword,
                                        confirmPassword:e.target.value
                                    })
                                }}/>
                              {passEye2 ? <RemoveRedEyeOutlinedIcon onClick={passHide2} className={'eyeicon_position'} /> :<VisibilityOffOutlinedIcon  onClick={passShow2} className={'eyeicon_position'} />}
                              {errors2.conformPassword && errors2.conformPassword.type === "required" && <p className='error mt-0'>Please enter Conform Password</p>}
                              {errors2.conformPassword && errors2.conformPassword.type === "pattern"  && <p className='error mt-0'>Please enter valid Conform Password</p>}
                              {passconError  && <p className='error mt-0'>New Password and Confirm Password does not match</p>}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{my:3}} >
                                <Button  className="customerLogin"  onClick={handleSubmit2(changeUserPassword)}>Submit</Button>
                            </Grid> 
                        </Grid>
                </Grid>
            </Box>
    </Modal>
{/* End Change password */}

{/* Start Change Number  */}
    <Modal
        open={openUserChangeNumber}
        // onClose={handleCloseUserChangeNumber}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
           <Box className="modalwidth" sx={style}>
                <Grid>
                    <CloseIcon onClick={()=>{handleCloseUserChangeNumber()}} className={"closeIconPosition"}/>
                    <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">
                        Change PhoneNumber
                    </Typography>
                </Grid>
                <Box sx={{width: '100%'}}>
                    <Grid container justifyContent="center" alignItems='baseline' rowSpacing={1}    columnSpacing = {{ xs: 1, sm: 2, md: 3 }}>
                        <Typography className="forgot_text" sx={{my:3}} align="center" variant="h6" gutterBottom component="div">
                            An OTP will be sent to given below phone number
                        </Typography>
                        <Grid item md={10}>
                            <Grid item sx={{mt:2}}>
                                <FormLabel>Phone number</FormLabel>
                                <TextField fullWidth name="phone" className={'text_field'} {...register3('phone',{required: true,pattern:/^\d{10}$/})}/>
                                {errors3.phone && errors3.phone.type === "required" && <p className='error mt-0'>Please enter phone number</p>}
                                {errors3.phone && errors3.phone.type === "pattern"  && <p className='error mt-0'>Please enter valid phone number</p>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid item sx={{my:3}} >
                            <Button className="customerLogin" 
                            onClick={handleSubmit3(changePhoneNumber)}>Submit</Button>
                        </Grid> 
                    </Grid>
                  </Box>
            </Box>
    </Modal>
{/* End Chnage Number */}
{/* Start  Saved address  */}
    <Modal
        open={savedAddress}
    // onClose={handleCloseSavedPassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modalwidth" sx={AddressStyle}>
            <Grid>
                <CloseIcon onClick={()=>{handleCloseSavedPassword()}} className={"closeIconPosition"}/>
                <Typography className="titleName"  align="center" variant="h4" gutterBottom component="div">Saved address</Typography>
            </Grid>
            <Box sx={{width: '100%'}}>
                <Grid container justifyContent="center" alignItems='baseline' rowSpacing={1} columnSpacing = {{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item md={5}>
                        <Grid item sx={{mt:2}}>
                            <FormLabel>Title</FormLabel>
                            <TextField fullWidth name="addresstitle" value={addAddress.addresstitle} className={'text_field'} onChange={(e)=>{setAddAddress({...addAddress , addresstitle : e.target.value})}} />
                            {titleError&&<p className='error mt-0'>Please enter title</p>}
                        </Grid>
                        <Grid item sx={{mt:2}}>
                                <Grid container justifyContent={'space-between'}>
                                    <FormLabel>Address</FormLabel>
                                    <MyLocationIcon className="locationIcon"
                                        onClick={()=>{
                                            handleOpenMap()
                                            setMap()
                                            }}
                                />
                                </Grid>
                                <Grid item>
                                    <TextField fullWidth value={addAddress.address}  className="address_field "  multiline variant='outlined' rows="2" size="small" onChange={(e)=>{setAddAddress({...addAddress,address : e.target.value})}}  
                                    />
                                    {addressError&&<p className='error mt-0'>Please enter address</p>}
                                </Grid>
                                <Grid item container justifyContent={'center'} sx={{my:3}}>
                                    <Button className="customerLogin"  onClick={addNewAddress} >Add</Button>
                                </Grid> 
                            </Grid>
                    </Grid>
                    <Grid item md={7} className="scrolladdress">
                        <Grid item className={'addressLine'}>
                            {savaddresses}
                        </Grid>
                    </Grid>
                </Grid>
                
                </Box>
        </Box>
    </Modal>
{/* End Saved address */}
    <Modal
       open={BookingModalopen}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
    >
       <Box sx={progressStyle} className="acceptanceModal">
           <Grid>
            {sorryScreen ? 
            <> 
            <Grid item>
                <Lottie
                options={radarOptions}
                height={100}
                width={100}/>
                <Typography className="serachingText">Waiting For Driver Acceptance....</Typography>
            </Grid>
            <Grid item sx={{mt:5}}>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" className='linear' value={progress} />
                </Box>
            </Grid>
            </> : <> <Grid item>
                <Lottie
                   options={lightOptions}
                   height={100}
                   width={100}/>
                   <Typography className="serachingText">Sorry, no ambulance available nearby</Typography>
               </Grid>
            </>
            }
            {/* <Grid item>
               <Button className='linerbtn' size="medium" onClick={BookingModalhandleClose}>Cancel</Button>
            </Grid> */}
        </Grid>
       </Box>
    </Modal>

{/* START OTP MODAL */}
    <Modal
        //open={true}
        open={changeNumberOtp}
        // onClose={handleCloseOtp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modalwidth" sx={style}>
            <CloseIcon onClick={()=>{handleCloseChangeNumberOtp()}} className={"closeIconPosition"}/>
            <Grid>
                <Typography className="titleName" align="center" variant="h4" gutterBottom component="div">
                    Verification Code
                </Typography>
                <Typography className="forgot_text" align="center" variant="h6" gutterBottom component="div">
                    Please enter the verification code sent to your mobile number
                </Typography>
            </Grid>
            <Grid container justifyContent='center' sx={{my:3}}>
                <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    className={"otpText"}
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure />
                {/* <ResendOTP handelResendClick={() => console.log("Resend clicked")} /> */}
            </Grid>
            <Grid container justifyContent='center'>
                <Grid item sx={{my:3}} >
                    <Button className="customerLogin" onClick={changePhoneNumberOtp}>Verify</Button>
                </Grid> 
            </Grid>
        {/* <Grid container justifyContent='center'>
                <Grid item>
                    <Typography>Didn't receive verification code? <span className='registerText' onClick={changePhoneNumberOtp}> Resend again</span> </Typography>
                </Grid> 
            </Grid> */}
        </Box>
    </Modal>

{/* END OTP MODAL */}
    <Modal
        open={openMap}
        // onClose={handleCloseMap}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={MapStyle} className="mapmodal">
                <Grid>
                    <Autocomplete onLoad={onLoad} onPlaceChanged={()=>onPlaceChanged(autocompleteRef.current.getPlace())}>
                        <TextField className="text_field" placeholder="search" 
                        //onChange={() => handlePlaceSelect(autocompleteRef.current.getPlace())}
                            fullWidth InputProps={{
                        startAdornment: (
                        <SearchIcon sx={{color:'#979899',marginLeft:'10px'}} />
                        ),
                        }} ref={originRef}/>
                    </Autocomplete>
                </Grid>
                <Grid className="mapHeight">
                {isLoaded? googleMap:<h1>loading...</h1>}
                </Grid>
                <Grid md={12}>
                    <Grid>
                        <Typography sx={{color:'#979899'}}>Pin location</Typography>
                    </Grid>
                    <Grid container sx={{mt:1}}>
                        <Grid md={0.5}>
                            <img src={LocationIcon} alt="icon"/>
                        </Grid>
                        <Grid md={7}>
                            <Typography></Typography>
                            <Typography sx={{color:'#979899'}}>{address}</Typography>
                        </Grid>
                        <Grid md={4.5}>
                            <Button className="confirmLocationbtn" onClick={()=>{handleCloseMap()}}>Confirm Location</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
    </Modal>

        <Snackbar open={smessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={SuccessClose}>
            <Alert onClose={SuccessClose} severity="success" className="snackBar">
              {successMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={emessage} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={ErrorClose}>
            <Alert className="snackError" onClose={ErrorClose} severity="error" >
              {errorMessage}
            </Alert>
        </Snackbar>
        </>

       
    )
}

export default CustomerHeader;