import React from "react";
import { Grid } from "@mui/material";
import Logo from "../Assets/icons/header_logo.svg";
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Avatar_img from '../Assets/icons/avatar.svg';
import { Typography } from "ui-neumorphism";
import './header.css';
import Container from "@mui/material/Container";
import Notification from '../Assets/icons/notification.svg';
import Logout from '../Assets/icons/logout.svg';
import { useNavigate } from "react-router-dom";
import { useState ,useEffect ,useRef} from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MailIcon from '@mui/icons-material/Mail';
import axios from "axios";
import AudioFile from "../../src/Assets/audio/ambulance_alarm.mp3";

const Header = () => {


    const [open, setOpen] = useState(false);
    const [name ,setName] = useState("")
    const handleClose = () => setOpen(false);
    const navigate =  useNavigate();
    const user = JSON.parse(sessionStorage.getItem("userType"));
    const [hsptlNotifications , setHsptlNotification] = useState(0);
    const audioRef = useRef(null);
    const buttonRef = useRef(null);
    //const audio = new Audio(AudioFile);
    
    const [notifications ,  setNotifications] = useState({
      empDriverCount: 0,
      empHspCount: 0,
      subDriverCount: 0,
      subEmpCount: 0,
      subHspCount: 0,
      totalCount: 0
    })
   

   
    const style = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: ' #F1F3F6 !important;',
        border: '2px solid white',
        boxShadow: 24,
        p: 5,
        outline:0,
        textAlign:'center',
        borderRadius:'10px'
      };
      
      const LogOut = () => {
          let user = JSON.parse(sessionStorage.getItem("userType"));
          if(user.userType === "Admin"){
            navigate('/adminLogin');
            sessionStorage.removeItem("userType")
          }
          else if(user.userType === "Employee"){
            navigate('/EmployeesLogin');
            sessionStorage.removeItem("userType")
          }
          else if(user.userType === "Sub_admin"){
            navigate('/subAdmin');
            sessionStorage.removeItem("userType")
          }
          else if(user.userType === "Hospital"){
            navigate('/HospitalLogin');
            sessionStorage.removeItem("userType")
          }
      }

    const [anchorElProfile, setAnchorElProfile] = React.useState(null);
    const openProfile = Boolean(anchorElProfile);
    const handleClickProfile = (event) => {
      if(user.type===1){
        setAnchorElProfile(event.currentTarget);
        getNotifications()
      }
        
    };
    const handleCloseProfile = () => {
        setAnchorElProfile(null);
    };

    const updateNotification = ()=>{
      setInterval(()=>{
        getNotifications()
      },10000)
      
    }
    const HospitalNotification = ()=>{
      
        setInterval(()=>{
          axios.get(`${process.env.REACT_APP_BASE_URL}hspNotifications/${user.id}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
            (responce) => {
              if(responce.data.status === 1){
                if(hsptlNotifications !== responce.data.hospitalNotifyCount){
                  if(responce.data.hospitalNotifyCount > hsptlNotifications){
                    setHsptlNotification(responce.data.hospitalNotifyCount)
                    sessionStorage.setItem('notify', responce.data.hospitalNotifyCount);
                  }
                }
              }else if(responce.data.status === 0){
                
              }
            }
          ).catch((error) => {console.log(error)})
          },10000)
        }
      

    const getNotifications = () => {
      axios.get(`${process.env.REACT_APP_BASE_URL}notifications`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          if(responce.data.status === 1){
            let data = responce.data.notification
            setNotifications({
              empDriverCount:data.empDriverCount , empHspCount:data.empHspCount , subDriverCount:data.subDriverCount , subEmpCount:data.subEmpCount , subHspCount:data.subHspCount , totalCount:data.totalCount

            })
           
          }else if(responce.data.status === 0){
            
          }
        }
      ).catch((error) => {console.log(error)})
    }

    const handleNavigation = (type) => {
      if(type === 1){
        axios.post(`${process.env.REACT_APP_BASE_URL}notifyView`,{from: 1, to: 1},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 1){
              navigate('/admin/Employees');
              getNotifications()
            }else if(responce.data.status === 0){
              
            }
          }
        ).catch((error) => {console.log(error)})
        
      }else if(type === 2){
        axios.post(`${process.env.REACT_APP_BASE_URL}notifyView`,{from: 1, to: 2},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 1){
              navigate('/admin/Hospitals');
              getNotifications()

            }else if(responce.data.status === 0){
              
            }
          }
        ).catch((error) => {console.log(error)})
        
      }else if(type === 3){
        axios.post(`${process.env.REACT_APP_BASE_URL}notifyView`,{from: 1, to: 3},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 1){
              navigate('/admin/Ambulance/AmbulanceDriver');
              getNotifications()

            }else if(responce.data.status === 0){
              
            }
          }
        ).catch((error) => {console.log(error)})
        
      }else if(type === 4){
        axios.post(`${process.env.REACT_APP_BASE_URL}notifyView`,{from: 2, to: 2},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 1){
              navigate('/admin/Hospitals');
              getNotifications()

            }else if(responce.data.status === 0){
              
            }
          }
        ).catch((error) => {console.log(error)})
        
      }else if(type === 5){
        axios.post(`${process.env.REACT_APP_BASE_URL}notifyView`,{from: 2, to: 3},{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
          (responce) => {
            if(responce.data.status === 1){
              navigate('/admin/Ambulance/AmbulanceDriver');
              getNotifications()

            }else if(responce.data.status === 0){
              
            }
          }
        ).catch((error) => {console.log(error)})
        
      }
    }
    const notificationView=()=>{
      navigate("/hospitals/Booking/OnGoing")
      axios.get(`${process.env.REACT_APP_BASE_URL}hspNotifyView/${user.id}`,{headers:{Authorization:process.env.REACT_APP_AUTHORIZATION}}).then(
        (responce) => {
          setHsptlNotification(0);
          pauseAudio();
          HospitalNotification();
        }
      ).catch((error) => {console.log(error)})
    } 

    useEffect(()=>{
      setName(user.name);
      getNotifications();
      updateNotification();
      if(user.type === 4){
        HospitalNotification()
      }
      // setUser(user);
    },[])

    useEffect(()=>{
      let notifying =  sessionStorage.getItem('notify');
      if(notifying>0){
        playAudio();
      }
    },[hsptlNotifications])

    const playAudio = () => {
      const audio = audioRef.current;
      audioRef.current.loop = true; 
      audio.play()
      
        .then(() => {
          // Audio started playing successfully
        })
        .catch(error => {
          // Handle any errors here
          console.error('Error playing audio:', error);
        });
    };

    const pauseAudio = () => {
      const audio = audioRef.current;
      sessionStorage.setItem('notify', 0);
      audioRef.current.loop = false;
      audio.pause();
    }
    useEffect(() => {
      const handleClick = () => {
        pauseAudio();
      };
  
      document.addEventListener('click', handleClick);
  
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, []);

    return (
      <>
      <audio ref={audioRef} src={AudioFile} />
      {/* <button ref={buttonRef} onClick={playAudio}>Play Audio</button> */}
            <Grid className={"header_box"} onClick={()=>pauseAudio()} >
                <Container className="widthSet">
                    <Grid container  direction="row" justifyContent="space-between" alignItems="center"  >
                        <Grid item>
                            <img src={Logo} alt="logo" className="cursordefault"/>
                        </Grid>
                        <Grid item>
                          <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt="Remy Sharp" src={Avatar_img} className="cursordefault"/>
                              <Typography className="userNameWidth" title={name}>{name}</Typography>
                              <IconButton
                              onClick={(event)=>{
                                handleClickProfile(event)
                                }}
                              >
                                  {user.type===1 && <Badge className="badgeIcon" badgeContent={notifications.totalCount} color="primary">
                                      <img src={Notification} alt="notification"/>
                                  </Badge>} 
                              </IconButton>
                              {user.type===4 &&<IconButton
                              onClick={notificationView}
                              >
                              <Badge className="badgeIcon" badgeContent={hsptlNotifications} color="primary">
                                <img src={Notification} alt="notification"/>
                              </Badge>
                              
                            </IconButton>}
                            {/* <Button onClick={()=>handleSound()}>Sound</Button> */}
                            {/* <Sound
                                url="cool_sound.mp3"
                                playStatus={Sound.status.PLAYING}
                                playFromPosition={300 }
                                onLoading={handleSongLoading}
                                onPlaying={handleSongPlaying}
                                onFinishedPlaying={handleSongFinishedPlaying}
                              /> */}
                            <IconButton  onClick={() => {
                                  setOpen(true); 
                                }} >
                                <img src={Logout} alt="logout"/>
                            </IconButton>
                          </Stack>
                      </Grid>
                    </Grid>
                </Container>
            </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Do you really want to Logout?
            </Typography>
            <Grid container justifyContent="space-around" sx={{ mt: 5 }}>
              <Button className="yesButton" onClick={LogOut}>Yes</Button>
              <Button className="noButton"  onClick={handleClose}>No</Button>
            </Grid>
        </Box>
      </Modal>

      <Menu
        anchorEl={anchorElProfile}
        id="account-menu"
        className="menuModal"
        open={openProfile}
        onClose={handleCloseProfile}
        onClick={handleCloseProfile}
        PaperProps={{
        elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Grid container justifyContent="center" >
          <Typography className="notificationHeading">Notifications</Typography>
        </Grid>
        <MenuItem className="itemBorder" sx={{p:2}}>
            <Grid container row>
              <Grid item container md={12} alignItems="center">
                  <Grid item md={1.5} className="iconBorder">
                      <ListItemIcon>
                          <Badge className="badgeIcon" badgeContent={notifications.subEmpCount} color="primary" >
                              <MailIcon />
                          </Badge>
                      </ListItemIcon>
                  </Grid>
                  <Grid item md={7}>
                      <Typography className="notificationemp">Sub admin <span className="notificationAdd">added</span> Employee</Typography> 
                  </Grid>
                  <Grid item md={3}>
                      <Button className="notificationView" onClick={()=>{
                        handleNavigation(1)
                      }}>View</Button>
                  </Grid>
              </Grid>
            </Grid>
        </MenuItem>
        <MenuItem className="itemBorder" sx={{p:2}}>
            <Grid row>
              <Grid container item md={12} alignItems="center">
                <Grid item md={1.5} className="iconBorder">
                    <ListItemIcon >
                      <Badge className="badgeIcon" badgeContent={notifications.subHspCount} color="primary">
                        <MailIcon />
                      </Badge>
                    </ListItemIcon>
                </Grid>
                <Grid item md={7}>
                  <Typography className="notificationemp">Sub admin <span className="notificationAdd">added</span> Hospital</Typography> 
                </Grid>
                <Grid item md={3}>
                    <Button className="notificationView" onClick={()=>{
                      handleNavigation(2)
                    }} >View</Button>
                </Grid>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem className="itemBorder">
              <Grid container row justifyContent = "center" alignItems = "center">
                <Grid container item md = {12} alignItems = "center">
                  <Grid item md = {1.5} className = "iconBorder">
                    <ListItemIcon>
                      <Badge className = "badgeIcon" badgeContent = {notifications.subDriverCount} color = "primary">
                        <MailIcon />
                      </Badge>
                    </ListItemIcon>
                  </Grid>
                  <Grid item md = {7}>
                    <Typography className = "notificationemp">Sub admin <span className = "notificationAdd">added</span> Ambulance driver</Typography> 
                  </Grid>
                  <Grid item md = {3}>
                    <Button className = "notificationView" onClick = {()=>{
                      handleNavigation(3)
                    }}>View</Button>
                  </Grid>
                </Grid>
              </Grid>
          </MenuItem>
          <MenuItem className="itemBorder" sx={{p:2}}>
            <Grid container row>
                <Grid container item md={12} alignItems="center">
                    <Grid item md={1.5} className="iconBorder">
                        <ListItemIcon>
                            <Badge className="badgeIcon" badgeContent={notifications.empHspCount} color="primary">
                                <MailIcon />
                            </Badge>
                        </ListItemIcon>
                    </Grid>
                  <Grid item md={7}>
                    <Typography className="notificationemp">Employee <span className="notificationAdd">added</span> Hospital</Typography> 
                  </Grid>
                  <Grid item md={3}>
                      <Button className="notificationView" onClick={()=>{
                        handleNavigation(4)
                      }}>View</Button>
                  </Grid>
                </Grid>
            </Grid>
        </MenuItem>
        <MenuItem sx={{p:2}}>
          <Grid container row>
            <Grid container item md = {12} alignItems="center">
              <Grid item md = {1.5} className="iconBorder">
                <ListItemIcon>
                  <Badge className = "badgeIcon" badgeContent = {notifications.empDriverCount} color = "primary">
                    <MailIcon/>
                  </Badge>
                </ListItemIcon>
              </Grid>
              <Grid item md = {7}>
                <Typography className = "notificationemp">Employee<span className = "notificationAdd">added</span> Ambulance driver</Typography> 
              </Grid>
              <Grid item md = {3}>
                <Button className = "notificationView" onClick = {()=>{
                    handleNavigation(5)
                  }}>View</Button>
              </Grid>  
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </>
    )
}
export default Header;